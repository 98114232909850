import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../components/context/AuthContext';
import axios from 'axios';

const SUBSCRIPTION_PLANS = {
  basic: {
    id: 'basic',
    name: 'Básico',
    price: 50,
    description: 'Plan Básico - Acceso a funciones esenciales',
    features: [
      'Acceso a funciones básicas',
      'Soporte por email',
      'Análisis básico de campañas'
    ],
    color: 'bg-blue-600',
    icon: 'fa-rocket'
  },
  performance: {
    id: 'performance',
    name: 'Performance',
    price: 250,
    description: 'Plan Performance - Funcionalidades avanzadas',
    features: [
      'Todas las funciones básicas',
      'Soporte prioritario',
      'Análisis avanzado de campañas',
      'Automatización de campañas'
    ],
    color: 'bg-purple-600',
    icon: 'fa-chart-line'
  },
  premium: {
    id: 'premium',
    name: 'Premium',
    price: 1,
    description: 'Plan Premium - Acceso completo a todas las funcionalidades',
    features: [
      'Todas las funciones avanzadas',
      'Soporte 24/7',
      'Análisis en tiempo real',
      'Automatización completa',
      'Acceso API'
    ],
    color: 'bg-green-600',
    icon: 'fa-crown'
  }
};

const Profile = () => {
  const { auth, updateUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeTab, setActiveTab] = useState('profile');
  const [profileData, setProfileData] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [processingSubscription, setProcessingSubscription] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    business_name: '',
    website_url: '',
    business_details: '',
    digital_experience: '',
    marketing_experience: '',
    industry: '',
    company_size: '',
    monthly_budget: '',
    has_team: false,
    team_size: null,
    notifications: {
      email: true,
      push: true,
      marketing: false
    }
  });
  useEffect(() => {
    if (profileData?.profile_image) {
      setImagePreview(profileData.profile_image);
    }
  }, [profileData?.profile_image]);
  const loadProfileData = useCallback(async () => {
    try {
      const response = await axios.get('/api/user');
      const data = response.data;
      
      // Actualizar el estado del perfil
      setProfileData(data);
      
      // Actualizar el formulario con los datos
      setFormData({
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        business_name: data.business_name || '',
        website_url: data.website_url || '',
        business_details: data.business_details || '',
        digital_experience: data.digital_experience || '',
        marketing_experience: data.marketing_experience || '',
        industry: data.industry || '',
        company_size: data.company_size || '',
        monthly_budget: data.monthly_budget || '',
        has_team: data.has_team || false,
        team_size: data.team_size || null,
        notifications: {
          email: data.notifications?.email ?? true,
          push: data.notifications?.push ?? true,
          marketing: data.notifications?.marketing ?? false
        }
      });

    } catch (err) {
      console.error('Error loading profile data:', err);
      setError(err.response?.data?.error || 'Error cargando datos del perfil');
    }
  }, []);

  const loadSubscriptionStatus = useCallback(async () => {
    try {
      const response = await axios.get('/api/subscriptions/status');
      if (response.data.subscription) {
        setSubscriptionStatus(response.data.subscription);
      } else {
        setSubscriptionStatus(null);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Error cargando estado de suscripción');
    }
  }, []);

  useEffect(() => {
    if (!auth.token) {
      setError('No hay token de autenticación');
      setLoading(false);
      return;
    }

    const loadData = async () => {
      try {
        setLoading(true);
        await Promise.all([loadProfileData(), loadSubscriptionStatus()]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [loadProfileData, loadSubscriptionStatus, auth.token]);

 const handlePhotoUpload = async (e) => {
  const file = e.target.files[0];
  if (!file) return;

  // Validar el tipo de archivo
  const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
  if (!validTypes.includes(file.type)) {
    setError('Por favor selecciona una imagen en formato JPG, PNG o GIF');
    return;
  }

  // Validar el tamaño del archivo (5MB)
  if (file.size > 5 * 1024 * 1024) {
    setError('La imagen no debe superar los 5MB');
    return;
  }

  const formData = new FormData();
  formData.append('profileImage', file);

  try {
    setUploadingImage(true);
    setError('');
    setSuccess('');

    // Log the file details for debugging
    console.log('File to upload:', {
      name: file.name,
      type: file.type,
      size: file.size
    });

    const imageResponse = await axios.post('/api/user/profile-image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Image upload response:', imageResponse.data);

    if (imageResponse.data && imageResponse.data.imageUrl) {
      // Actualizar el perfil con la nueva imagen
      const updateData = {
        profile_image: imageResponse.data.imageUrl
      };

      const userResponse = await axios.put('/api/user', updateData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('User update response:', userResponse.data);

      // Actualizar los estados
      setProfileData(prev => ({
        ...prev,
        profile_image: imageResponse.data.imageUrl
      }));
      
      updateUser(prev => ({
        ...prev,
        profile_image: imageResponse.data.imageUrl
      }));

      setImagePreview(imageResponse.data.imageUrl);
      setSuccess('Foto de perfil actualizada exitosamente');
    }
  } catch (err) {
    console.error('Full error details:', err);
    setError(err.response?.data?.error || 'Error actualizando foto de perfil');
  } finally {
    setUploadingImage(false);
  }
};
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (!formData.first_name || !formData.last_name) {
        setError('El nombre y apellido son requeridos');
        return;
      }

      const updateData = {
        first_name: formData.first_name.trim(),
        last_name: formData.last_name.trim(),
        business_name: formData.business_name?.trim(),
        website_url: formData.website_url?.trim(),
        business_details: formData.business_details?.trim(),
        digital_experience: formData.digital_experience,
        marketing_experience: formData.marketing_experience,
        industry: formData.industry,
        company_size: formData.company_size,
        monthly_budget: formData.monthly_budget ? parseFloat(formData.monthly_budget) : null,
        has_team: formData.has_team,
        team_size: formData.team_size,
        notifications: formData.notifications
      };

      const response = await axios.put('/api/user', updateData);

      setProfileData(response.data);
      updateUser(response.data);
      setSuccess('Perfil actualizado exitosamente');
      window.scrollTo(0, 0);
    } catch (err) {
      console.error('Profile update error:', err);
      setError(err.response?.data?.error || 'Error actualizando el perfil');
      window.scrollTo(0, 0);
    }
  };

  const handleSubscribe = async (planId) => {
    try {
      setProcessingSubscription(true);
      setError('');
      
      const response = await axios.post('/api/subscriptions/create', {
        plan_id: planId
      });

      if (response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      } else if (response.data.init_point) {
        window.location.href = response.data.init_point;
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Error al suscribirse al plan');
      window.scrollTo(0, 0);
    } finally {
      setProcessingSubscription(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm('¿Estás seguro que deseas cancelar tu suscripción?')) return;

    try {
      setProcessingSubscription(true);
      await axios.post('/api/subscriptions/cancel');
      await loadSubscriptionStatus();
      setSuccess('Suscripción cancelada exitosamente');
    } catch (err) {
      setError(err.response?.data?.error || 'Error cancelando suscripción');
    } finally {
      setProcessingSubscription(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-300">Cargando información...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white mb-2">Mi Cuenta</h1>
            <p className="text-gray-400">Administra tu perfil y suscripción</p>
          </div>
          <div className="flex items-center space-x-4">
            <button className="text-gray-300 hover:text-white transition-colors duration-150">
              <i className="fas fa-bell text-xl"></i>
            </button>
            <div className="relative w-10 h-10 rounded-full overflow-hidden bg-gray-700 border-2 border-gray-600">
    {imagePreview ? (
  <img
    src={imagePreview}
    alt="Profile"
    className="w-full h-full object-cover"
    onError={(e) => {
      console.log('Error loading image:', e); // Para debugging
      e.target.src = '/default-avatar.png';
      setImagePreview(null);
    }}
  />
) : (
  <div className="w-full h-full flex items-center justify-center text-gray-400">
    <i className="fas fa-user text-4xl"></i>
  </div>
)}
            </div>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded-lg mb-6">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-3 rounded-lg mb-6">
            {success}
          </div>
        )}

        {/* Tabs */}
        <div className="mb-8 border-b border-gray-700">
          <div className="flex space-x-8">
            <button
              onClick={() => setActiveTab('profile')}
              className={`pb-4 relative ${
                activeTab === 'profile'
                  ? 'text-white border-b-2 border-blue-500'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              <i className="fas fa-user mr-2"></i>
              Perfil
            </button>
            <button
              onClick={() => setActiveTab('subscription')}
              className={`pb-4 relative ${
                activeTab === 'subscription'
                  ? 'text-white border-b-2 border-blue-500'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              <i className="fas fa-credit-card mr-2"></i>
              Suscripción
            </button>
          </div>
        </div>

        {/* Profile Content */}
        {activeTab === 'profile' && (
          <div className="bg-gray-800 rounded-lg p-6">
            {/* Profile Photo */}
            <div className="mb-8 p-6 bg-gray-700 rounded-lg">
              <div className="flex flex-col md:flex-row items-center gap-6">
                <div className="relative w-32 h-32">
                  <div className="w-full h-full rounded-full overflow-hidden bg-gray-600 border-2 border-gray-500">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Profile"
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.src = '/default-avatar.png';
                          setImagePreview(null);
                        }}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center text-gray-400">
                        <i className="fas fa-user text-4xl"></i>
                      </div>
                    )}
                  </div>
                  {uploadingImage && (
                    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                    </div>
                  )}
                </div>
                
                <div className="flex-1 w-full">
                  <h3 className="text-lg font-semibold mb-3 text-white">Foto de Perfil</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoUpload}
                    className="w-full px-3 py-2 bg-gray-600 rounded-lg text-gray-300 
                             file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm 
                             file:font-semibold file:bg-gray-700 file:text-white 
                             hover:file:bg-gray-600 cursor-pointer"
                    disabled={uploadingImage}
                  />
                  <p className="text-sm text-gray-400 mt-2">
                    Formatos soportados: JPG, PNG, GIF (máx. 5MB)
                  </p>
                </div>
              </div>
            </div>

            {/* Profile Form */}
            <form onSubmit={handleProfileUpdate} className="space-y-6">
              {/* Personal Information */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-white">Información Personal</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-300 mb-2">Nombre</label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.first_name}
                      onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Apellido</label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.last_name}
                      onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Business Information */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-white">Información del Negocio</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-gray-300 mb-2">Nombre del Negocio</label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.business_name}
                      onChange={(e) => setFormData({ ...formData, business_name: e.target.value })}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">URL del Sitio Web</label>
                    <input
                      type="url"
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.website_url}
                      onChange={(e) => setFormData({ ...formData, website_url: e.target.value })}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Detalles del Negocio</label>
                    <textarea
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500 min-h-[100px]"
                      value={formData.business_details}
                      onChange={(e) => setFormData({ ...formData, business_details: e.target.value })}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Additional Information */}
              <div>
                <h3 className="text-lg font-semibold mb-4 text-white">Información Adicional</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-300 mb-2">Industria</label>
                    <select
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.industry}
                      onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
                    >
                      <option value="">Seleccionar...</option>
                      <option value="ecommerce">E-commerce</option>
                      <option value="saas">SaaS</option>
                      <option value="retail">Retail</option>
                      <option value="services">Servicios</option>
                      <option value="other">Otro</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Tamaño de la Empresa</label>
                    <select
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.company_size}
                      onChange={(e) => setFormData({ ...formData, company_size: e.target.value })}
                    >
                      <option value="">Seleccionar...</option>
                      <option value="1-10">1-10 empleados</option>
                      <option value="11-50">11-50 empleados</option>
                      <option value="51-200">51-200 empleados</option>
                      <option value="201-500">201-500 empleados</option>
                      <option value="500+">Más de 500 empleados</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Experiencia en Marketing Digital</label>
                    <select
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.digital_experience}
                      onChange={(e) => setFormData({ ...formData, digital_experience: e.target.value })}
                    >
                      <option value="">Seleccionar...</option>
                      <option value="beginner">Principiante</option>
                      <option value="intermediate">Intermedio</option>
                      <option value="advanced">Avanzado</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-300 mb-2">Presupuesto Mensual (USD)</label>
                    <input
                      type="number"
                      className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg 
                               focus:outline-none focus:border-blue-500"
                      value={formData.monthly_budget}
                      onChange={(e) => setFormData({ ...formData, monthly_budget: e.target.value })}
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end pt-6">
                <button
                  type="submit"
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                           transition-colors duration-150 flex items-center"
                >
                  <i className="fas fa-save mr-2"></i>
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        )}

        {/* Subscription Content */}
        {activeTab === 'subscription' && (
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-2xl font-semibold text-white mb-6">Gestión de Suscripción</h2>

              {/* Current Subscription Status */}
              {subscriptionStatus ? (
                <div className="bg-gray-700 rounded-lg p-6 mb-8">
                  <h3 className="text-xl font-semibold text-white mb-4">Plan Actual</h3>
                  <div className="space-y-4">
                    <div className="flex items-center justify-between border-b border-gray-600 pb-3">
                      <span className="text-gray-300">Plan:</span>
                      <span className="font-medium text-white">
                        {SUBSCRIPTION_PLANS[subscriptionStatus.plan_id]?.name || 'Plan Desconocido'}
                      </span>
                    </div>
                    <div className="flex items-center justify-between border-b border-gray-600 pb-3">
                      <span className="text-gray-300">Estado:</span>
                      <span className={`px-3 py-1 rounded-full text-white ${
                        subscriptionStatus.status === 'active' ? 'bg-green-500' :
                        subscriptionStatus.status === 'cancelled' ? 'bg-red-500' :
                        'bg-yellow-500'
                      }`}>
                        {subscriptionStatus.status === 'active' ? 'Activo' :
                         subscriptionStatus.status === 'cancelled' ? 'Cancelado' :
                         'Pendiente'}
                      </span>
                    </div>
                    {subscriptionStatus.next_payment_date && (
                      <div className="flex items-center justify-between">
                        <span className="text-gray-300">Próximo pago:</span>
                        <span className="text-white">
                          {new Date(subscriptionStatus.next_payment_date).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                  
                  {subscriptionStatus.status === 'active' && (
                    <div className="mt-6">
                      <button
                        onClick={handleCancelSubscription}
                        disabled={processingSubscription}
                        className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700
                                 transition-colors duration-150 disabled:opacity-50"
                      >
                        <i className="fas fa-times-circle mr-2"></i>
                        {processingSubscription ? 'Procesando...' : 'Cancelar Suscripción'}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="bg-blue-500 bg-opacity-10 border border-blue-500 text-blue-500 
                              px-4 py-3 rounded-lg mb-8">
                  No tienes una suscripción activa. Selecciona un plan para comenzar.
                </div>
              )}

              {/* Available Plans */}
              <div>
                <h3 className="text-xl font-semibold text-white mb-6">Planes Disponibles</h3>
                <div className="grid gap-6">
                  {Object.entries(SUBSCRIPTION_PLANS).map(([key, plan]) => {
                    const isCurrentPlan = subscriptionStatus?.plan_id === key;
                    const isActive = isCurrentPlan && subscriptionStatus?.status === 'active';

                    return (
                      <div
                        key={key}
                        className={`relative rounded-lg p-6 transition-all duration-150 ${
                          isCurrentPlan
                            ? 'border-2 border-blue-500 bg-gray-700'
                            : 'border border-gray-600 hover:border-blue-500'
                        }`}
                      >
                        {isActive && (
                          <div className="absolute -top-3 right-6">
                            <span className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
                              Plan Actual
                            </span>
                          </div>
                        )}

                        <div className="flex flex-col md:flex-row justify-between items-start gap-6">
                          <div>
                            <div className={`w-12 h-12 rounded-lg ${plan.color} flex items-center 
                                          justify-center mb-4`}>
                              <i className={`fas ${plan.icon} text-xl text-white`}></i>
                            </div>
                            <h4 className="text-xl font-bold text-white mb-2">{plan.name}</h4>
                            <p className="text-gray-400 mb-4">{plan.description}</p>
                            <ul className="space-y-2">
                              {plan.features.map((feature, index) => (
                                <li key={index} className="flex items-center text-gray-300">
                                  <i className="fas fa-check text-green-500 mr-2"></i>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="flex flex-col items-center md:items-end">
                            <div className="text-3xl font-bold text-white mb-4">
                              ${plan.price}
                              <span className="text-gray-400 text-base font-normal">/mes</span>
                            </div>
                            <button
                              onClick={() => handleSubscribe(key)}
                              disabled={processingSubscription || isActive}
                              className={`px-6 py-2 rounded-lg transition-colors duration-150 ${
                                isActive
                                  ? 'bg-gray-600 text-gray-300 cursor-not-allowed'
                                  : 'bg-blue-600 text-white hover:bg-blue-700'
                              } disabled:opacity-50`}
                            >
                              {processingSubscription
                                ? 'Procesando...'
                                : isActive
                                ? 'Plan Actual'
                                : 'Suscribirse'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;