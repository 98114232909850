import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Search, ThumbsUp, MessageCircle, Share2, Clock, Menu, 
  Monitor, Smartphone, Send, Loader, Sparkles, Wand2, X,
  Globe, DollarSign, Target, Check, Upload, Image, AlertCircle,ChevronDown, BarChart2,
  HelpCircle, Plus, Calendar, Store, CreditCard, Tag, 
  Package, Bell, BarChart3
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AuthContext } from '../components/context/AuthContext';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Message = ({ message, isTyping, setCampaignPreview }) => {
  const isAI = message.role === 'assistant';
  const isSystem = message.role === 'system';

  return (
    <div className={`flex items-end gap-2 mb-4 ${isAI ? 'justify-start' : 'justify-end'}`}>
      {isAI && (
        <div className="h-8 w-8 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 
          flex items-center justify-center flex-shrink-0">
          <Wand2 className="h-4 w-4 text-white" />
        </div>
      )}
      
      <div className={`max-w-[80%] px-4 py-3 shadow-md ${
        isAI ? 'bg-gray-800 rounded-3xl rounded-bl-md' : 
        isSystem ? 'bg-gray-900 rounded-3xl' :
        'bg-gradient-to-r from-blue-600 to-indigo-600 rounded-3xl rounded-br-md'
      }`}>
        {isTyping ? (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 py-2">
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '0ms' }} />
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '150ms' }} />
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" 
                style={{ animationDelay: '300ms' }} />
            </div>
            <span className="text-xs text-gray-400">Generando respuesta...</span>
          </div>
        ) : (
          <>
            <div className="text-sm leading-relaxed text-gray-100">
              {message.content}
            </div>
            {message.timestamp && (
              <div className="text-[10px] mt-1.5 text-gray-400">
                {new Date(message.timestamp).toLocaleTimeString([], { 
                  hour: '2-digit', 
                  minute: '2-digit'
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
// GooglePreview Component
const GooglePreview = ({ campaignPreview }) => (
  <div className="bg-gray-800 p-4 rounded-xl shadow-md border border-gray-700 hover:shadow-lg transition-all duration-200">
    <div className="mb-3 flex items-center gap-2 border border-gray-700 rounded-full p-2 shadow-sm bg-gray-900">
      <Search className="w-4 h-4 text-blue-400" />
      <span className="text-sm text-gray-300 flex-1 truncate">
        {campaignPreview.adContent?.keywords?.[0] || `Búsqueda relacionada con ${campaignPreview.name}`}
      </span>
    </div>

    <div className="mb-1 inline-block text-[10px] px-2 py-0.5 bg-blue-900 text-blue-300 rounded-sm">
      Anuncio
    </div>

    <div className="space-y-2">
      <div>
        <div className="text-blue-400 text-base leading-tight cursor-pointer hover:underline">
          {campaignPreview.adContent?.titulos?.[0] || campaignPreview.name} | {campaignPreview.name}
        </div>
        <div className="text-emerald-400 text-xs mb-1">
          {campaignPreview.adContent?.urls?.display || "www.tudominio.com/landing"}
        </div>
        <div className="text-xs text-gray-300">
          {campaignPreview.adContent?.descripcion || 
           `Descubre cómo podemos ayudarte con ${campaignPreview.objective}. Presupuestos desde $${campaignPreview.budget}/día.`}
        </div>
      </div>
      
      <div className="grid grid-cols-3 gap-1 text-blue-400 text-xs pt-2 border-t border-gray-700">
        {(campaignPreview.adContent?.extensiones || ["Contacto", "Ubicaciones", "Reseñas"]).map((extension, index) => (
          <div key={index} className="cursor-pointer hover:underline p-1.5 rounded hover:bg-gray-700 transition-colors">
            {extension}
          </div>
        ))}
      </div>
    </div>
  </div>
);
const MetaCatalogDebug = ({ campaignPreview, auth, API_URL }) => {
  const [catalogData, setCatalogData] = useState({
    products: [],
    loading: true,
    error: null,
    rawResponse: null
  });

  useEffect(() => {
    const fetchAndDebug = async () => {
      if (!campaignPreview?.catalog_settings?.catalog_id) {
        setCatalogData(prev => ({ ...prev, loading: false }));
        return;
      }

      try {
        const response = await fetch(
          `${API_URL}/api/meta/catalogs/${campaignPreview.catalog_settings.catalog_id}/products`,
          { 
            headers: { 
              Authorization: `Bearer ${auth.token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        const data = await response.json();
        console.log('Raw API Response:', data);
        
        setCatalogData({
          products: data.products || [],
          loading: false,
          error: null,
          rawResponse: data
        });
      } catch (error) {
        console.error('Debug Error:', error);
        setCatalogData({
          products: [],
          loading: false,
          error: error.message,
          rawResponse: null
        });
      }
    };

    fetchAndDebug();
  }, [campaignPreview?.catalog_settings?.catalog_id]);

  if (catalogData.loading) {
    return <div className="p-4 text-gray-200">Cargando datos del catálogo...</div>;
  }

  if (catalogData.error) {
    return (
      <div className="p-4 bg-red-500/20 rounded-lg">
        <h3 className="text-red-400 font-medium">Error al cargar el catálogo:</h3>
        <p className="text-sm text-gray-200 mt-2">{catalogData.error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6 bg-gray-800 rounded-xl p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-100">Debug: Datos del Catálogo</h3>
        
        <div className="mt-4 p-4 bg-gray-700/30 rounded-lg space-y-2">
          <h4 className="text-sm font-medium text-gray-200">Configuración:</h4>
          <pre className="text-xs text-gray-400 overflow-auto">
            {JSON.stringify(campaignPreview?.catalog_settings, null, 2)}
          </pre>
        </div>

        <div className="mt-4 p-4 bg-gray-700/30 rounded-lg space-y-2">
          <h4 className="text-sm font-medium text-gray-200">Respuesta Raw API:</h4>
          <pre className="text-xs text-gray-400 overflow-auto">
            {JSON.stringify(catalogData.rawResponse, null, 2)}
          </pre>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4">
          {catalogData.products.map((product) => (
            <div key={product.id} className="bg-gray-700/30 rounded-lg overflow-hidden">
              <div className="aspect-square relative">
                {product.image_url ? (
                  <img 
                    src={product.image_url} 
                    alt={product.name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/api/placeholder/200/200';
                    }}
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-600">
                    <span className="text-xs text-gray-400">Sin imagen</span>
                  </div>
                )}
              </div>
              <div className="p-3">
                <h5 className="text-sm font-medium text-gray-200 truncate">{product.name || 'Sin nombre'}</h5>
                <p className="text-xs text-gray-400 mt-1">
                  {product.price ? `$${product.price}` : 'Precio no disponible'}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetaCatalogPreview = ({ campaignPreview, auth, API_URL, onUpdateCampaign }) => {
  const [catalogData, setCatalogData] = useState({
    catalogs: [],
    selectedCatalog: campaignPreview?.catalog_settings?.catalog_id || null,
    selectedPage: campaignPreview?.catalog_settings?.page_id || null,
    products: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/meta/catalogs`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
  
        if (response.data?.catalogs) {
          setCatalogData(prev => ({
            ...prev,
            catalogs: response.data.catalogs.map(catalog => ({
              ...catalog,
              available_pages: catalog.available_pages?.map(page => ({
                ...page,
                access_token: page.access_token
              })) || []
            })),
            loading: false
          }));
        }
      } catch (error) {
        console.error('Error fetching catalogs:', error);
        setCatalogData(prev => ({
          ...prev,
          loading: false,
          error: 'Error al cargar catálogos'
        }));
      }
    };
  
    fetchCatalogs();
  }, [auth.token, API_URL]);

  const fetchProducts = async (catalogId) => {
    if (!catalogId) return;
    setCatalogData(prev => ({ ...prev, loading: true }));

    try {
      const response = await axios.get(
        `${API_URL}/api/meta/catalogs/${catalogId}/products`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.data?.products) {
        setCatalogData(prev => ({
          ...prev,
          products: response.data.products,
          loading: false
        }));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setCatalogData(prev => ({
        ...prev,
        loading: false,
        error: error.message
      }));
    }
  };

  const handleCatalogSelect = async (catalogId) => {
    if (!catalogId) return;
    
    setCatalogData(prev => ({
      ...prev,
      selectedCatalog: catalogId,
      selectedPage: null,
      products: []
    }));

    if (onUpdateCampaign && campaignPreview) {
      onUpdateCampaign({
        ...campaignPreview,
        catalog_settings: {
          ...campaignPreview.catalog_settings,
          catalog_id: catalogId,
          page_id: null
        }
      });
    }

    await fetchProducts(catalogId);
  };

  const handleAssociatePage = async (pageId) => {
    if (!catalogData.selectedCatalog || !pageId) return;
    
    const selectedPage = getAvailablePagesForCatalog(catalogData.selectedCatalog)
        .find(page => page.id === pageId);
        
    if (!selectedPage) {
        toast.error('No se encontró la página seleccionada');
        return;
    }
    
    if (!selectedPage.access_token) {
        toast.error('La página no tiene un token de acceso válido');
        return;
    }
    
    try {
        const response = await axios.post(
            `${API_URL}/api/meta/catalogs/${catalogData.selectedCatalog}/associate-page`,
            {
                page_id: pageId,
                page_access_token: selectedPage.access_token
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        
        if (response.data.success) {
            toast.success('Página asociada exitosamente');
        }
    } catch (error) {
        toast.error(error.response?.data?.error || 'Error al asociar la página');
        console.error('Error associating page:', error);
    }
  };

  const getAvailablePagesForCatalog = (catalogId) => {
    const catalog = catalogData.catalogs.find(cat => cat.id === catalogId);
    return catalog?.available_pages?.map(page => ({
      id: page.id,
      name: page.name,
      access_token: page.access_token,
      category: page.category
    })) || [];
  };

  const formatPrice = (price) => {
    if (typeof price === 'object' && price !== null) {
      return price.amount || 0;
    }
    
    if (typeof price === 'string' && price.includes('$U')) {
      const numericPrice = price
        .replace('$U', '')
        .replace(/\./g, '')
        .replace(',', '.')
        .trim();
      return parseFloat(numericPrice) || 0;
    }
    
    return parseFloat(price || 0);
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (catalogData.loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader className="w-6 h-6 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6 bg-gray-800 rounded-xl p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-100">Catálogo de Productos</h3>

        {catalogData.error && (
          <div className="p-4 bg-red-500/20 rounded-lg">
            <p className="text-sm text-red-400">{catalogData.error}</p>
          </div>
        )}

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-200">
            Seleccionar Catálogo:
          </label>
          <select
            value={catalogData.selectedCatalog || ''}
            onChange={(e) => handleCatalogSelect(e.target.value)}
            className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-3 py-2 text-sm text-gray-200"
          >
            <option value="">Selecciona un catálogo</option>
            {catalogData.catalogs.map((catalog) => (
              <option key={catalog.id} value={catalog.id}>
                {`${catalog.name} (${catalog.product_count.active} activos de ${catalog.product_count.total} productos)`}
              </option>
            ))}
          </select>
        </div>

        {catalogData.selectedCatalog && (
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">
              Asociar Página:
            </label>
            <select
              value={catalogData.selectedPage || ''}
              onChange={(e) => handleAssociatePage(e.target.value)}
              className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-3 py-2 text-sm text-gray-200"
            >
              <option value="">Selecciona una página</option>
              {getAvailablePagesForCatalog(catalogData.selectedCatalog).map((page) => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {catalogData.products.length > 0 && (
          <div className="relative px-1">
            <Slider {...sliderSettings} className="product-carousel -mx-2">
              {catalogData.products.map((product) => (
                <div key={product.id} className="px-2">
                  <div className="bg-gray-700/30 rounded-lg overflow-hidden h-full">
                    <div className="aspect-square">
                      <img 
                        src={product.image_url} 
                        alt={product.name || 'Producto'}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/api/placeholder/200/200';
                        }}
                      />
                    </div>
                    
                    <div className="p-4">
                      <h5 className="text-sm font-medium text-gray-200 mb-2 line-clamp-2">
                        {product.name || 'Producto sin nombre'}
                      </h5>
                      
                      <div className="space-y-2">
                        <p className="text-lg font-semibold text-blue-400">
                          ${formatPrice(product.price).toLocaleString('es-UY', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </p>
                        {product.availability === 'out of stock' ? (
                          <span className="inline-block text-xs font-medium text-red-400 px-2 py-1 bg-red-400/10 rounded">
                            Agotado
                          </span>
                        ) : (
                          <span className="inline-block text-xs font-medium text-green-400 px-2 py-1 bg-green-400/10 rounded">
                            Disponible
                          </span>
                        )}
                      </div>

                      {product.brand && (
                        <p className="text-xs text-gray-400 mt-2">
                          Marca: {product.brand}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}

        {catalogData.selectedCatalog && catalogData.products.length === 0 && !catalogData.loading && (
          <div className="text-center py-8 bg-gray-800 rounded-lg">
            <p className="text-sm text-gray-400">
              No se encontraron productos en el catálogo
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
// MetaPreview Component
const MetaPreview = ({ campaignPreview, onUpdateCampaign, onUploadImage, onUpdateSettings, auth, setCampaignPreview }) => {
  const [selectedImageFormat, setSelectedImageFormat] = useState('feed');
  const [images, setImages] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  
  const [campaignDates, setCampaignDates] = useState({
    startDate: campaignPreview?.metaSettings?.campaignDates?.startDate || '',
    endDate: campaignPreview?.metaSettings?.campaignDates?.endDate || ''
  });

  useEffect(() => {
    if (campaignPreview?.adContent?.image_urls) {
      setImages(campaignPreview.adContent.image_urls);
    }
  }, [campaignPreview?.adContent?.image_urls]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else {
      setDragActive(false);
    }
  };

  const handleImageUploadLocal = async (file) => {
    if (!file) return;
    
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      const response = await axios.post(`${API_URL}/api/meta/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${auth.token}`
        }
      });
  
      if (response.data.url) {
        setImages(prev => ({
          ...prev,
          [selectedImageFormat]: response.data.url
        }));
  
        setCampaignPreview(prev => ({
          ...prev,
          adContent: {
            ...prev.adContent,
            image_urls: {
              ...(prev.adContent?.image_urls || {}),
              [selectedImageFormat]: response.data.url
            }
          }
        }));
      }
    } catch (error) {
      console.error('Error uploading image:', error.response?.data || error);
      alert('Error al subir la imagen. Por favor, intenta de nuevo.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handleImageUploadLocal(file);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const file = e.dataTransfer.files?.[0];
    if (file) {
      await handleImageUploadLocal(file);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-800 rounded-2xl p-6 space-y-6">
        {/* Campaign Period */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200 flex items-center gap-2">
            <Clock className="w-4 h-4" />
            Periodo de campaña
          </label>
          <div className="grid grid-cols-2 gap-4">
            <input
              type="date"
              value={campaignDates.startDate}
              onChange={(e) => {
                const newDates = {
                  ...campaignDates,
                  startDate: e.target.value
                };
                setCampaignDates(newDates);
                onUpdateSettings?.({ campaignDates: newDates });
              }}
              className="bg-gray-700/50 border border-gray-600 rounded-xl px-4 py-3 text-sm text-gray-200"
            />
            <input
              type="date"
              value={campaignDates.endDate}
              onChange={(e) => {
                const newDates = {
                  ...campaignDates,
                  endDate: e.target.value
                };
                setCampaignDates(newDates);
                onUpdateSettings?.({ campaignDates: newDates });
              }}
              className="bg-gray-700/50 border border-gray-600 rounded-xl px-4 py-3 text-sm text-gray-200"
            />
          </div>
        </div>

        {/* Image Upload Section */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-200">
            Imagen del anuncio
          </label>
          <div
            className={`relative overflow-hidden ${
              selectedImageFormat === 'story' ? 'aspect-[9/16]' : 
              selectedImageFormat === 'square' ? 'aspect-square' : 
              'aspect-[1.91/1]'
            } rounded-xl transition-all duration-300`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
            
            {images[selectedImageFormat] ? (
              <div className="relative group">
                <img 
                  src={images[selectedImageFormat]}
                  alt="Ad Preview"
                  className="w-full h-full object-cover rounded-xl"
                />
                <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg backdrop-blur-sm text-white text-sm font-medium transition-colors"
                  >
                    Cambiar imagen
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => fileInputRef.current?.click()}
                className={`w-full h-full flex flex-col items-center justify-center border-2 border-dashed rounded-xl
                  ${dragActive ? 'border-blue-500 bg-blue-500/10' : 'border-gray-600 hover:border-blue-500/50 hover:bg-gray-700/50'}
                  transition-all duration-200`}
              >
                {isUploading ? (
                  <Loader className="w-6 h-6 text-gray-400 animate-spin" />
                ) : (
                  <>
                    <Upload className="w-6 h-6 text-gray-400 mb-2" />
                    <p className="text-sm text-gray-400">
                      {dragActive ? 'Suelta la imagen aquí' : 'Arrastra o haz clic para subir'}
                    </p>
                  </>
                )}
              </button>
            )}
          </div>

          {/* Image Format Selector */}
          <div className="flex gap-2">
            {['feed', 'story', 'square'].map(format => (
              <button
                key={format}
                onClick={() => setSelectedImageFormat(format)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                  selectedImageFormat === format
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-700/50 text-gray-300 hover:bg-gray-700'
                }`}
              >
                {format.charAt(0).toUpperCase() + format.slice(1)}
              </button>
            ))}
          </div>
        </div>

{campaignPreview?.platform?.toLowerCase().includes('catalog') && (
  <div className="mt-6">
    <MetaCatalogPreview
      campaignPreview={campaignPreview}
      onUpdateCampaign={onUpdateCampaign}
      auth={auth}
      API_URL={API_URL}
    />
  </div>
)}
        {/* Preview Section */}
        <div className="bg-gray-900 rounded-xl overflow-hidden">
          <div className="p-4 space-y-4">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 flex items-center justify-center text-white font-bold text-sm">
                {campaignPreview?.name?.[0] || 'A'}
              </div>
              <div>
                <div className="font-semibold text-gray-100">{campaignPreview?.name}</div>
                <div className="text-xs text-gray-400">Patrocinado</div>
              </div>
            </div>
            
            <p className="text-sm text-gray-300">
              {campaignPreview?.adContent?.descripcion}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// CampaignDetails Component
const CampaignDetails = ({ campaignPreview }) => (
  <div className="bg-gray-800 rounded-xl shadow-md border border-gray-700 p-4 mb-4">
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <div className={`w-2 h-2 rounded-full ${
          campaignPreview.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'
        }`} />
        <span className="text-xs font-medium text-gray-300">
          {campaignPreview.status === 'active' ? 'Campaña Activa' : 'Borrador'}
        </span>
      </div>
      <h3 className="text-lg font-semibold text-gray-100">{campaignPreview.name}</h3>
      <div className="flex flex-wrap gap-3 text-sm text-gray-400">
        {campaignPreview.budget && (
          <span className="flex items-center gap-1">
            <DollarSign className="w-4 h-4" />
            ${campaignPreview.budget}/día
          </span>
        )}
        {campaignPreview.platform && (
          <span className="flex items-center gap-1">
            <Globe className="w-4 h-4" />
            {campaignPreview.platform}
          </span>
        )}
        {campaignPreview.objective && (
          <span className="flex items-center gap-1">
            <Target className="w-4 h-4" />
            {campaignPreview.objective}
          </span>
        )}
      </div>
    </div>
  </div>
);

// PreviewHeader Component
const PreviewHeader = ({ deviceView, setDeviceView, activeTab, setActiveTab }) => (
  <div className="">
    <div className="flex items-center justify-between">
     <br></br>
     <h2></h2>
      <div className="flex gap-2">
  
      </div>
    </div>
    
    <div className="flex gap-2">
     
    
    </div>
  </div>
);

// EmptyPreview Component
const EmptyPreview = () => (
  <div className="flex flex-col items-center justify-center h-full text-center px-6">
    <div className="h-16 w-16 rounded-2xl bg-blue-900/30 flex items-center justify-center mb-4">
      <Sparkles className="h-8 w-8 text-blue-400" />
    </div>
    <div className="max-w-sm">
      <h3 className="text-xl font-bold text-gray-100 mb-2">
        Tu vista previa aparecerá aquí
      </h3>
      <p className="text-sm text-gray-400">
        Describe tu campaña en el chat y te ayudaré a crear una vista previa profesional.
      </p>
    </div>
  </div>
);
const QuickOptions = ({ onSelect }) => {
  const options = [
    {
      id: 1,
      text: "Hola! Quiero crear una campaña. me ayudas?",
      icon: Plus,
      title: "Crear una campaña",
      description: "Te ayudo a crear una campaña personalizada"
    },
    {
      id: 2,
      text: "Hola! Quiero saber las estadisticas de mis campañas",
      icon: BarChart2,
      title: "Ver estadísticas",
      description: "Revisa el rendimiento de tus campañas"
    },
    {
      id: 3,
      text: "Hola! Quiero saber cual es el Roi de mis campañas",
      icon: DollarSign,
      title: "Ver ROI",
      description: "Analiza el retorno de inversión"
    },
    {
      id: 4,
      text: "Hola! Quiero que me respondas ciertas dudas de mis campañas",
      icon: HelpCircle,
      title: "Resolver dudas",
      description: "Respondo tus preguntas sobre campañas"
    }
  ];

  return (
    <>
      {options.map((option) => {
        const Icon = option.icon;
        return (
          <button
            key={option.id}
            onClick={() => onSelect(option.text)}
            className="p-3 bg-gray-800/50 rounded-lg hover:bg-gray-700/80 transition-all duration-200
              text-left group border border-gray-700/50 hover:border-blue-500/50"
          >
            <div className="flex items-center gap-3">
              <div className={`h-8 w-8 rounded-lg flex items-center justify-center
                ${option.id === 1 ? 'bg-blue-500/10' : 
                  option.id === 2 ? 'bg-green-500/10' : 
                  option.id === 3 ? 'bg-indigo-500/10' : 
                  'bg-purple-500/10'}`}
              >
                <Icon className={`h-4 w-4 
                  ${option.id === 1 ? 'text-blue-400' : 
                    option.id === 2 ? 'text-green-400' : 
                    option.id === 3 ? 'text-indigo-400' : 
                    'text-purple-400'}`} 
                />
              </div>
              <div>
                <span className="text-sm font-medium text-gray-200 group-hover:text-white">
                  {option.title}
                </span>
                <p className="text-xs text-gray-400 mt-0.5">
                  {option.description}
                </p>
              </div>
            </div>
          </button>
        );
      })}
    </>
  );
};



const CampaignPreviewSection = ({ 
  campaignPreview, 
  deviceView, 
  activeTab, 
  isCampaignReady, 
  onConfirm,
  onUpdateCampaign,
  onUploadImage,
  onUpdateSettings,
  setCampaignPreview,
  auth,
  isCreatingCampaign
}) => {
  if (!campaignPreview || !campaignPreview.name || !campaignPreview.platform) {
    return null;
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`transition-all duration-300 ${
        deviceView === 'mobile' ? 'max-w-sm' : 'max-w-2xl'
      } mx-auto`}
    >
      <CampaignDetails campaignPreview={campaignPreview} />
      {activeTab === 'google' ? (
        <GooglePreview campaignPreview={campaignPreview} />
      ) : (
        <MetaPreview
          campaignPreview={campaignPreview}
          onUpdateCampaign={onUpdateCampaign}
          onUploadImage={onUploadImage}
          onUpdateSettings={onUpdateSettings}
          auth={auth}
          setCampaignPreview={setCampaignPreview}
        />
      )}
      {isCampaignReady && (
        <div className="mt-6 flex justify-center items-center gap-4 bg-gray-800 p-4 rounded-xl shadow-md">
          <span className="text-sm font-medium text-gray-300">¿Confirmar campaña?</span>
          <button
            onClick={onConfirm}
            disabled={isCreatingCampaign}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
              transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isCreatingCampaign ? (
              <>
                <Loader className="w-4 h-4 animate-spin" />
                <span>Creando...</span>
              </>
            ) : (
              <>
                <Check className="w-4 h-4" />
                <span>Confirmar</span>
              </>
            )}
          </button>
        </div>
      )}
    </motion.div>
  );
};

// Main AICampaignCreation Component
const AICampaignCreation = ({ onCampaignCreated }) => {
  // States
  const [messages, setMessages] = useState([{
    role: 'assistant',
    content: '👋 ¡Hola! Soy CH3, tu asistente de campañas publicitarias. ¿En qué puedo ayudarte hoy?',
    timestamp: new Date().toISOString()
  }]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [campaignPreview, setCampaignPreview] = useState(null);
  const [deviceView, setDeviceView] = useState('desktop');
  const [activeTab, setActiveTab] = useState('google');
  const [showQuickOptions, setShowQuickOptions] = useState(true);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [conversationContext, setConversationContext] = useState({});
  const [metaSettings, setMetaSettings] = useState(null);

  // Refs and Context
  const chatRef = useRef(null);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const chatId = params.get('chat');
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  // Add this useEffect for screen size detection
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      });
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Effects
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (chatId) {
      const loadChatSession = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/api/chat/sessions/${chatId}`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`
              }
            }
          );
          
          if (response.data.messages?.length > 0) {
            setMessages(response.data.messages);
            setShowQuickOptions(response.data.messages.length === 1);
          }
        } catch (error) {
          console.error('Error loading chat:', error);
          setMessages([{
            role: 'assistant',
            content: '👋 ¡Hola! Soy CH3, tu asistente de campañas publicitarias. ¿En qué puedo ayudarte hoy?',
            timestamp: new Date().toISOString()
          }]);
          setShowQuickOptions(true);
        }
      };

      loadChatSession();
    }
  }, [chatId, auth.token]);



  // Handlers
  const handleQuickOptionSelect = (text) => {
    setShowQuickOptions(false);
    setInputValue(text);
    const event = { preventDefault: () => {} };
    handleSendMessage(event);
  };
// En AICampaignCreation, agrega esta función antes del return
const handleCampaignConfirm = async () => {
  if (!campaignPreview) return;

  setIsCreatingCampaign(true);
  try {
    const campaignData = {
      name: campaignPreview.name,
      platform: campaignPreview.platform,
      budget: campaignPreview.budget,
      objective: campaignPreview.objective,
      ad_content: campaignPreview.adContent,
      meta_settings: metaSettings
    };

    const response = await axios.post(
      `${API_URL}/create_campaign`,
      campaignData,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    setMessages(prev => [...prev, {
      role: 'system',
      content: '✅ ¡Campaña creada exitosamente!',
      timestamp: new Date().toISOString()
    }]);

    // Limpiar el preview y resetear estados
    setCampaignPreview(null);
    setMetaSettings(null);
    
    if (onCampaignCreated) {
      onCampaignCreated(response.data);
    }

  } catch (error) {
    setMessages(prev => [...prev, {
      role: 'system',
      content: `❌ Error al crear la campaña: ${error.response?.data?.error || error.message}`,
      timestamp: new Date().toISOString()
    }]);
  } finally {
    setIsCreatingCampaign(false);
  }
};

const handleSendMessage = async (e) => {
  e.preventDefault();
  if (!inputValue.trim() || isTyping) return;

  const newMessage = { 
    role: 'user', 
    content: inputValue,
    timestamp: new Date().toISOString()
  };

  setShowQuickOptions(false);
  setMessages(prev => [...prev, newMessage]);
  setInputValue('');
  setIsTyping(true);
  setIsCreatingCampaign(true);

  try {
    let catalogsResponse = null; // Declarar aquí para tener acceso en todo el scope
    
    // Si es la primera interacción con catálogos, obtener la lista
    if (inputValue.toLowerCase().includes('catalogo') && !campaignPreview?.catalog_settings) {
      catalogsResponse = await axios.get(
        `${API_URL}/api/meta/catalogs`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      console.log('Available catalogs:', catalogsResponse.data);
    }

    let currentChatId = chatId;
    
    if (!currentChatId) {
      const sessionResponse = await axios.post(
        `${API_URL}/api/chat/sessions`,
        { title: `Chat ${new Date().toLocaleString()}` },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      currentChatId = sessionResponse.data.id;
      navigate(`/dashboard?chat=${currentChatId}`);
    }

    await axios.post(
      `${API_URL}/api/chat/sessions/${currentChatId}/messages`,
      newMessage,
      { headers: { Authorization: `Bearer ${auth.token}` } }
    );

    const aiResponse = await axios.post(
      `${API_URL}/create_campaign_conversation`,
      { 
        message: inputValue,
        session_id: currentChatId,
        campaign_preview: campaignPreview,
        available_catalogs: catalogsResponse?.data?.catalogs || []
      },
      { headers: { Authorization: `Bearer ${auth.token}` } }
    );

    console.log('AI Response:', aiResponse.data);

    const assistantMessage = {
      role: 'assistant',
      content: aiResponse.data.message,
      timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, assistantMessage]);

    if (aiResponse.data.campaign_details) {
      console.log('Campaign details received:', aiResponse.data.campaign_details);
    
      const catalog_settings = aiResponse.data.campaign_details.catalog_settings;
      
      // Solo agregamos catalog_settings si tenemos un catalogId válido
      const newPreview = {
        name: aiResponse.data.campaign_details.nombre,
        platform: aiResponse.data.campaign_details.plataforma,
        budget: aiResponse.data.campaign_details.presupuesto,
        objective: aiResponse.data.campaign_details.objetivo,
        status: 'draft',
        adContent: aiResponse.data.ad_content || {},
        // Solo agregamos catalog_settings si hay un catalogId válido y no es un placeholder
        catalog_settings: catalog_settings?.catalog_id && 
                         catalog_settings.catalog_id !== 'ID del catálogo' ? {
          catalog_id: catalog_settings.catalog_id,
          page_id: catalog_settings.page_id
        } : null
      };
      
      console.log('Setting new campaign preview:', newPreview);
      
      setCampaignPreview(newPreview);
    
      if (aiResponse.data.campaign_details.plataforma?.toLowerCase().includes('meta') || 
          aiResponse.data.campaign_details.plataforma?.toLowerCase().includes('catalog')) {
        setActiveTab('meta');
      }
    }

    await axios.post(
      `${API_URL}/api/chat/sessions/${currentChatId}/messages`,
      assistantMessage,
      { headers: { Authorization: `Bearer ${auth.token}` } }
    );

  } catch (error) {
    console.error('Error:', error);
    setMessages(prev => [...prev, {
      role: 'system',
      content: '❌ Error al procesar el mensaje. Por favor, intenta de nuevo.',
      timestamp: new Date().toISOString()
    }]);
  } finally {
    setIsTyping(false);
    setIsCreatingCampaign(false);
  }
};

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      {/* Main Container */}
      <div className="flex flex-1 overflow-hidden">
        {/* Chat Section */}
        <div className={`flex flex-col flex-1 min-w-0 transition-all duration-300 ease-in-out
          ${campaignPreview?.name && campaignPreview?.platform ? 'lg:w-1/2' : 'w-full'}`}>
          {/* Header */}
          <div className="px-4 sm:px-6 py-3 sm:py-4 bg-gradient-to-r from-gray-900 to-gray-800 border-b border-gray-700">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 sm:gap-4">
                <div className="h-10 w-10 sm:h-12 sm:w-12 rounded-xl  
                  backdrop-blur-sm flex items-center justify-center">
                  <img src="/ch3.png" alt="CH3" className="h-8 relative z-10" />
                </div>
                <div>
                
                 
                </div>
              </div>
              <button
                onClick={() => {
                  setMessages([{
                    role: 'assistant',
                    content: '👋 ¡Hola! Soy CH3, tu asistente de campañas publicitarias. ¿En qué puedo ayudarte hoy?',
                    timestamp: new Date().toISOString()
                  }]);
                  setShowQuickOptions(true);
                  setCampaignPreview(null);
                }}
                className="px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg bg-gray-800 hover:bg-gray-700 text-gray-300 
                  hover:text-white transition-colors text-sm sm:text-base font-medium"
                title="Iniciar una nueva conversación"
              >
                Nuevo chat
              </button>
            </div>
          </div>
  
          {/* Messages Container */}
          <div className="flex-1 overflow-y-auto" ref={chatRef}>
            <div className="max-w-3xl mx-auto p-3 sm:p-4 md:p-6">
            {/* Mensajes normales */}
{messages.map((message, index) => (
  <div key={index} className={`mb-4 sm:mb-6 ${
    message.role === 'assistant' ? 'pr-4 sm:pr-12' : 'pl-4 sm:pl-12'
  }`}>
    <Message 
      message={message} 
      isTyping={false}
      setCampaignPreview={setCampaignPreview}
    />
  </div>
))}

{/* Indicador de typing como un mensaje separado */}
{isTyping && (
  <div className="pr-4 sm:pr-12 mb-4 sm:mb-6">
    <Message 
      message={{ role: 'assistant', content: '' }}
      isTyping={true}
      setCampaignPreview={setCampaignPreview}
    />
  </div>
)}
              
  
            </div>
          </div>
  
          {/* Input Area */}
          <div className="px-4 py-3   bg-gray-900/80 backdrop-blur-sm">
  <div className="max-w-4xl mx-auto">
    {showQuickOptions && messages.length === 1 && !isTyping && (
      <div className="mb-3"> {/* Espacio entre las opciones y el input */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <QuickOptions onSelect={handleQuickOptionSelect} />
        </div>
      </div>
    )}
    
    <form onSubmit={handleSendMessage} className="flex items-center gap-2">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={isTyping}
        placeholder={isTyping ? "Procesando mensaje..." : "Describe tu campaña aquí..."}
        className="flex-1 h-11 px-4 bg-gray-800/90 rounded-lg
          text-sm text-gray-100 placeholder-gray-500 
          border border-gray-700/50 
          focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50
          transition-all duration-200"
      />
      <button
        type="submit"
        disabled={isTyping || !inputValue.trim()}
        className="h-11 px-5 bg-blue-600 text-white rounded-lg
          hover:bg-blue-700 transition-colors 
          disabled:opacity-50 disabled:hover:bg-blue-600
          flex items-center gap-2 text-sm whitespace-nowrap"
      >
        {isTyping ? (
          <>
            <Loader className="w-4 h-4 animate-spin" />
            <span>Procesando</span>
          </>
        ) : (
          <>
            <Send className="w-4 h-4" />
            <span>Enviar</span>
          </>
        )}
      </button>
    </form>
  </div>
</div>
        </div>
  
        {/* Preview Section - Desktop */}
        <AnimatePresence>
          {campaignPreview?.name && campaignPreview?.platform && (
            <motion.div 
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: '50%', opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
              className="hidden lg:flex lg:flex-col h-full bg-gray-900 border-l border-gray-700"
            >
              <PreviewHeader 
                deviceView={deviceView}
                setDeviceView={setDeviceView}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onClose={() => setCampaignPreview(null)}
              />
              <div className="flex-1 overflow-y-auto">
                <div className="p-4 sm:p-6">
                  <CampaignPreviewSection 
                    campaignPreview={campaignPreview}
                    deviceView={deviceView}
                    activeTab={activeTab}
                    isCampaignReady={true}
                    onConfirm={handleCampaignConfirm}
                    onUpdateCampaign={(updatedCampaign) => setCampaignPreview(updatedCampaign)}
                    onUpdateSettings={(newSettings) => {
                      setCampaignPreview(prev => ({
                        ...prev,
                        metaSettings: { ...(prev?.metaSettings || {}), ...newSettings }
                      }));
                    }}
                    setCampaignPreview={setCampaignPreview}
                    auth={auth}
                    isCreatingCampaign={isCreatingCampaign}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
  
        {/* Preview Section - Mobile/Tablet Modal */}
        <AnimatePresence>
          {campaignPreview?.name && campaignPreview?.platform && (screenSize.isMobile || screenSize.isTablet) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-50 lg:hidden"
            >
              <motion.div 
                className="absolute inset-0 bg-black/50 backdrop-blur-sm"
                onClick={() => setCampaignPreview(null)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              />
              <motion.div 
                className="absolute inset-x-0 bottom-0 bg-gray-900 rounded-t-2xl max-h-[90vh] overflow-hidden"
                initial={{ y: '100%' }}
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                transition={{ type: 'spring', damping: 25, stiffness: 300 }}
              >
                <div className="sticky top-0 flex items-center justify-between p-4 border-b border-gray-700 bg-gray-900/80 backdrop-blur-sm">
                  <h3 className="text-lg font-semibold text-gray-100">Vista previa de campaña</h3>
                  <button
                    onClick={() => setCampaignPreview(null)}
                    className="p-2 rounded-lg hover:bg-gray-800 text-gray-400 hover:text-white transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
                <div className="overflow-y-auto">
                  <div className="p-4">
                    <CampaignPreviewSection 
                      campaignPreview={campaignPreview}
                      deviceView={deviceView}
                      activeTab={activeTab}
                      isCampaignReady={true}
                      onConfirm={handleCampaignConfirm}
                      onUpdateCampaign={(updatedCampaign) => setCampaignPreview(updatedCampaign)}
                      onUpdateSettings={(newSettings) => {
                        setCampaignPreview(prev => ({
                          ...prev,
                          metaSettings: { ...(prev?.metaSettings || {}), ...newSettings }
                        }));
                      }}
                      setCampaignPreview={setCampaignPreview}
                      auth={auth}
                      isCreatingCampaign={isCreatingCampaign}
                    />
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AICampaignCreation;