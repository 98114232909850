import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext'; 
import { ArrowRight, ArrowLeft, Check } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const OnboardingModal = ({ onComplete }) => {
 const navigate = useNavigate();
 const location = useLocation();
 const { auth, updateUser } = useAuth();
 const [step, setStep] = useState(1);
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState(null);
 const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
 const [formData, setFormData] = useState({
   first_name: '',
   businessName: '',
   websiteUrl: '',
   businessDetails: '',
   previousAds: {
     hasAdvertised: null, 
     platforms: []
   },
   digitalExperience: '',
   firstAction: '',
   selected_plan: 'premium',
 });
 useEffect(() => {
  const createFreeSubscription = async () => {
    try {
      const response = await fetch(`${API_URL}/api/subscriptions/create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          plan_id: 'premium'
        })
      });

      if (!response.ok) {
        console.error('Error creating free subscription');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  createFreeSubscription();
}, [auth.token]);
 const totalSteps = 7;
 const progress = (step / totalSteps) * 100;

 const saveProgress = async (data) => {
  try {
    const response = await fetch(`${API_URL}/api/user/save-onboarding-progress`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...data, selected_plan: 'premium' })
    });

     if (!response.ok) {
       const error = await response.json();
       throw new Error(error.error || 'Error al guardar el progreso');
     }

     return await response.json();
   } catch (err) {
     console.error('Error saving progress:', err);
     setError(err.message);
   }
 };

 useEffect(() => {
   const checkOnboardingStatus = async () => {
     try {
       const response = await fetch(`${API_URL}/api/user/check-onboarding`, {
         headers: {
           'Authorization': `Bearer ${auth.token}`,
           'Content-Type': 'application/json'
         }
       });
       
       if (!response.ok) throw new Error('Error al verificar el estado del onboarding');
       
       const data = await response.json();
       if (data.onboarding_completed) {
         navigate('/dashboard');
       }
     } catch (error) {
       console.error('Error checking onboarding status:', error);
       setError('Error al verificar el estado del onboarding');
     }
   };

   checkOnboardingStatus();
 }, [auth.token, navigate]);

 const validateStep = useCallback(() => {
   try {
     switch (step) {
       case 1:
         if (!formData.first_name?.trim()) throw new Error('El nombre es requerido');
         return true;
       case 2:
         if (!formData.businessName?.trim()) throw new Error('El nombre del negocio es requerido');
         return true;
       case 3:
         if (!formData.websiteUrl?.trim()) throw new Error('La URL del sitio web es requerida');
         return true;
       case 4:
         if (!formData.businessDetails?.trim()) throw new Error('Los detalles del negocio son requeridos');
         return true;
       case 5:
         if (formData.previousAds.hasAdvertised === null) throw new Error('Indica si ya has promocionado tu negocio');
         if (formData.previousAds.hasAdvertised && formData.previousAds.platforms.length === 0) {
           throw new Error('Selecciona al menos una plataforma de anuncios');
         }
         return true;
       case 6:
         if (!formData.digitalExperience) throw new Error('La experiencia en Marketing Digital es requerida');
         return true;
       case 7:
         if (!formData.firstAction) throw new Error('Selecciona la primera acción que te gustaría realizar');
         return true;
       default:
         return false;
     }
    } catch (e) {
      setError(e.message);
      return false;
    }
  }, [step, formData]);

 const handleNextStep = useCallback(async () => {
   setHasAttemptedSubmit(true);
   if (validateStep()) {
     await saveProgress(formData);
     setStep(s => s + 1);
     setHasAttemptedSubmit(false);
     setError(null);
     
     setTimeout(() => {
       const input = document.querySelector('input[autoFocus], textarea[autoFocus]');
       if (input) input.focus();
     }, 50);
   }
 }, [validateStep, formData]);

 useEffect(() => {
   const handleKeyPress = async (event) => {
     if (event.key === 'Enter' && step < totalSteps) {
       event.preventDefault();
       await handleNextStep();
     }
   };

   document.addEventListener('keypress', handleKeyPress);
   return () => document.removeEventListener('keypress', handleKeyPress);
 }, [step, totalSteps, handleNextStep]);

 const handlePrevStep = useCallback(() => {
   setStep(s => s - 1);
   setError(null);
   setHasAttemptedSubmit(false);
 }, []);

 const handleSubmit = useCallback(async () => {
   if (loading) return;

   try {
     setLoading(true);
     setError(null);

     // Create free subscription first
     const subscriptionResponse = await fetch(`${API_URL}/api/subscriptions/create`, {
       method: 'POST',
       headers: {
         'Authorization': `Bearer ${auth.token}`,
         'Content-Type': 'application/json'
       },
       body: JSON.stringify({
         plan_id: 'premium'
       })
     });

     if (!subscriptionResponse.ok) {
       throw new Error('Error al crear la suscripción gratuita');
     }

     const response = await fetch(`${API_URL}/api/user/onboarding`, {
       method: 'POST',
       headers: {
         'Authorization': `Bearer ${auth.token}`,
         'Content-Type': 'application/json'
       },
       body: JSON.stringify({...formData, selected_plan: 'premium'})
     });

     if (!response.ok) {
       const errorData = await response.json();
       throw new Error(errorData.error || 'Error al completar el onboarding');
     }

     const responseData = await response.json();
     await updateUser({
       ...responseData,
       onboarding_completed: true
     });

     if (onComplete) {
       onComplete(formData);
     } else {
       navigate('/dashboard');
     }
   } catch (error) {
     console.error('Error completing onboarding:', error);
     setError(error.message);
   } finally {
     setLoading(false);
   }
 }, [auth.token, formData, loading, onComplete, updateUser, navigate]);

 const renderStep = () => {
   switch (step) {
     case 1:
       return (
         <div>
           <h2 className="text-2xl font-bold mb-6">¿Cuál es tu nombre?</h2>
           <input
             autoFocus
             type="text"
             name="first_name"
             value={formData.first_name}
             onChange={(e) => setFormData(prev => ({ ...prev, first_name: e.target.value }))}
             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
             placeholder="Tu nombre"
           />
           {hasAttemptedSubmit && !formData.first_name?.trim() && (
             <p className="text-red-500 text-sm mt-2">El nombre es requerido</p>
           )}
         </div>
       );

     case 2:
       return (
         <div>
           <h2 className="text-2xl font-bold mb-6">¿Cómo se llama tu negocio?</h2>
           <input
             autoFocus
             type="text"
             name="businessName"
             value={formData.businessName}
             onChange={(e) => setFormData(prev => ({ ...prev, businessName: e.target.value }))}
             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
             placeholder="Nombre del negocio"
           />
           {hasAttemptedSubmit && !formData.businessName?.trim() && (
             <p className="text-red-500 text-sm mt-2">El nombre del negocio es requerido</p>
           )}
         </div>
       );

     case 3:
       return (
         <div>
           <h2 className="text-2xl font-bold mb-6">¿Cuál es la URL de tu sitio web?</h2>
           <input
             autoFocus
             type="url"
             name="websiteUrl"
             value={formData.websiteUrl}
             onChange={(e) => setFormData(prev => ({ ...prev, websiteUrl: e.target.value }))}
             className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
             placeholder="https://"
           />
           {hasAttemptedSubmit && !formData.websiteUrl?.trim() && (
             <p className="text-red-500 text-sm mt-2">La URL del sitio web es requerida</p>
           )}
         </div>
       );

     case 4:
       return (
         <div>
           <h2 className="text-2xl font-bold mb-6">Cuéntanos más sobre tu negocio</h2>
           <textarea
             autoFocus
             name="businessDetails"
             value={formData.businessDetails}
             onChange={(e) => setFormData(prev => ({ ...prev, businessDetails: e.target.value }))}
             onKeyPress={(e) => {
               if (e.key === 'Enter') {
                 e.preventDefault();
                 handleNextStep();
               }
             }}
             className="w-full h-32 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
             placeholder="Describe tu negocio..."
           />
           {hasAttemptedSubmit && !formData.businessDetails?.trim() && (
             <p className="text-red-500 text-sm mt-2">Los detalles del negocio son requeridos</p>
           )}
         </div>
       );

       case 5:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Ya promocionaste tu negocio con anuncios digitales?</h2>
            <div className="space-y-4">
              {['Sí', 'No'].map((option) => (
                <button
                  key={option}
                  onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      previousAds: { 
                        ...prev.previousAds,
                        hasAdvertised: option === 'Sí',
                        platforms: option === 'No' ? [] : prev.previousAds.platforms
                      }
                    }));
                    if (option === 'No') {
                      handleNextStep();
                    }
                  }}
                  className={`w-full px-4 py-3 border rounded-md text-left ${
                    formData.previousAds.hasAdvertised === (option === 'Sí')
                      ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                      : 'border-gray-300 hover:border-indigo-300'
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
  
            {formData.previousAds.hasAdvertised && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">¿En qué plataformas?</h3>
                <div className="space-y-3">
                  {['Google', 'Meta', 'TikTok'].map((platform) => (
                    <button
                      key={platform}
                      onClick={() => {
                        const platforms = formData.previousAds.platforms;
                        setFormData(prev => ({
                          ...prev,
                          previousAds: {
                            ...prev.previousAds,
                            platforms: platforms.includes(platform)
                              ? platforms.filter(p => p !== platform)
                              : [...platforms, platform]
                          }
                        }));
                      }}
                      className={`w-full px-4 py-3 border rounded-md text-left ${
                        formData.previousAds.platforms.includes(platform)
                          ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                          : 'border-gray-300 hover:border-indigo-300'
                      }`}
                    >
                      {platform}
                    </button>
                  ))}
                </div>
                {/* Only show error if user has clicked next and selected "Sí" but no platforms */}
                {hasAttemptedSubmit && 
                 formData.previousAds.hasAdvertised && 
                 formData.previousAds.platforms.length === 0 && (
                  <p className="text-red-500 text-sm mt-2">Selecciona al menos una plataforma</p>
                )}
              </div>
            )}
          </div>
        );
  
      case 6:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-6">¿Cuánta experiencia tienes en Marketing Digital?</h2>
            <div className="space-y-3">
              {['NINGUNA', 'ALGO DE', 'SOY UN EXPERTO'].map((level) => (
                <button
                  key={level}
                  onClick={() => {
                    setFormData(prev => ({ ...prev, digitalExperience: level }));
                    setError(null); // Limpiar cualquier error existente
                  }}
                  className={`w-full px-4 py-3 border rounded-md text-left ${
                    formData.digitalExperience === level
                      ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                      : 'border-gray-300 hover:border-indigo-300'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
            {/* Only show error if user has clicked next and no experience level is selected */}
            {hasAttemptedSubmit && !formData.digitalExperience && (
              <p className="text-red-500 text-sm mt-2">La experiencia en Marketing Digital es requerida</p>
            )}
          </div>
        );

     case 7:
       return (
         <div>
           <h2 className="text-2xl font-bold mb-6">¿Qué te gustaría hacer primero?</h2>
           <div className="space-y-4">
             {[
               { id: 'create-campaign', name: '¡QUIERO CREAR MI PRIMERA CAMPAÑA!' },
               { id: 'explore', name: 'QUIERO EXPLORAR YO MISMO' }
             ].map((action) => (
               <button
                 key={action.id}
                 onClick={() => {
                  setFormData(prev => ({ ...prev, firstAction: action.id }));
                  handleSubmit();
                }}
                className={`w-full px-4 py-3 border rounded-md text-left ${
                  formData.firstAction === action.id
                    ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                    : 'border-gray-300 hover:border-indigo-300'
                }`}
              >
                {action.name}
              </button>
            ))}
          </div>
          {hasAttemptedSubmit && !formData.firstAction && (
            <p className="text-red-500 text-sm mt-2">Selecciona la primera acción que te gustaría realizar</p>
          )}
        </div>
      );

    default:
      return null;
  }
};

return (
  <div className="min-h-screen flex flex-col md:flex-row">
    {/* Left side - Form */}
    <div className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-8 min-h-screen md:min-h-0 bg-white">
      <div className="w-full max-w-md">
        {/* Progress bar */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-gray-500">Paso {step} de {totalSteps}</span>
            <span className="text-sm font-medium">{Math.round(progress)}%</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-full bg-indigo-600 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>

        {/* Error message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
            <p className="text-red-600 text-sm">{error}</p>
          </div>
        )}

        {/* Form content */}
        <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
          {renderStep()}

          {/* Navigation buttons */}
          <div className="mt-8 flex justify-between">
            {step > 1 && (
              <button
                onClick={handlePrevStep}
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Anterior
              </button>
            )}
            
            <button
              onClick={step < totalSteps ? handleNextStep : handleSubmit}
              disabled={loading}
              className={`flex items-center px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 ml-auto ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? (
                <span>Cargando...</span>
              ) : (
                <>
                  {step === totalSteps ? 'Completar' : 'Siguiente'}
                  {step < totalSteps && <ArrowRight className="w-4 h-4 ml-2" />}
                  {step === totalSteps && <Check className="w-4 h-4 ml-2" />}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>

    {/* Right side - Image */}
    <div className="hidden md:block w-full md:w-1/2 bg-cover bg-center">
      <div 
        className="h-full relative"
        style={{
          backgroundImage: `url('https://wallpapers.com/images/hd/world-globe-l658bqf4z68pij5a.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-white text-center p-8">
            <h1 className="text-4xl font-bold mb-4">Build your success</h1>
            <p className="text-xl">Create successful marketing campaigns.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default OnboardingModal;