import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import * as XLSX from 'xlsx';
import { 
  BarChart2, TrendingUp, Users, DollarSign, Target, Activity, 
  TrendingDown, ArrowUp, ArrowDown, Zap, Clock, Radio, Share2, 
  Eye, MousePointer, ShoppingBag, Box, Maximize2, PieChart, 
  BarChart, RefreshCw, AlertTriangle, Calendar, X, DownloadIcon
} from 'lucide-react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, PieChart as RePieChart, Pie, Cell,
  BarChart as ReBarChart, Bar, Legend, Area, AreaChart,
  ComposedChart
} from 'recharts';
import axios from 'axios';
import { format, parseISO, subDays } from 'date-fns';
import { es } from 'date-fns/locale';

const API_URL = process.env.REACT_APP_API_URL;

// Función para obtener el rango de fechas del último año
const getLastYearDates = () => {
  const end_date = new Date();
  const start_date = new Date();
  start_date.setFullYear(start_date.getFullYear() - 1);
  return {
    start_date: start_date.toISOString().split('T')[0],
    end_date: end_date.toISOString().split('T')[0]
  };
};
const exportToExcel = (performanceData) => {
  // Preparar los datos para el Excel
  const excelData = performanceData.map(item => {
    const campaign = item.google_metrics || item.meta_metrics;
    return {
      'Fecha': format(parseISO(item.date), 'yyyy-MM-dd'),
      'Campaign ID': campaign.campaign_id,
      'Nombre Campaña': campaign.campaign_name,
      'Estado': campaign.status,
      'Canal': campaign.channel_type,
      'Estrategia de Puja': campaign.bidding_strategy,
      'Impresiones': campaign.impressions,
      'Clicks': campaign.clicks,
      'Costo': campaign.cost,
      'Conversiones': campaign.conversions,
      'CPC': campaign.cost / campaign.clicks || 0,
      'CTR': ((campaign.clicks / campaign.impressions * 100) || 0).toFixed(2) + '%',
      'Valor de Conversión': campaign.conversion_value || 0,
      'Costo por Conversión': (campaign.cost / campaign.conversions || 0),
      'Valor por Conversión': (campaign.conversion_value / campaign.conversions || 0),
      'Plataforma': item.google_metrics ? 'Google Ads' : 'Meta Ads'
    };
  });

  // Crear un nuevo libro de trabajo
  const wb = XLSX.utils.book_new();
  // Convertir los datos a una hoja de trabajo
  const ws = XLSX.utils.json_to_sheet(excelData);

  // Ajustar el ancho de las columnas
  const columnWidths = Object.keys(excelData[0]).map(key => ({
    wch: Math.max(key.length, 15)
  }));
  ws['!cols'] = columnWidths;

  // Añadir la hoja al libro
  XLSX.utils.book_append_sheet(wb, ws, 'Métricas de Campañas');

  // Generar el archivo y descargarlo
  XLSX.writeFile(wb, `metricas_campanas_${format(new Date(), 'yyyy-MM-dd')}.xlsx`);
};
// Componente MetricCard
const MetricCard = ({ title, value = 0, previousValue = null, icon: Icon, color, prefix = '', suffix = '' }) => {
  const percentageChange = previousValue !== null && previousValue !== 0
    ? ((value - previousValue) / previousValue) * 100 
    : 0;

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-300">
      <div className="flex justify-between items-start">
        <div>
          <p className="text-gray-400 text-sm">{title}</p>
          <h3 className="text-2xl font-bold text-white mt-1">
            {prefix}{typeof value === 'number' ? value.toLocaleString('es-ES', { maximumFractionDigits: 2 }) : value}{suffix}
          </h3>
          {previousValue !== null && (
            <div className={`flex items-center mt-2 ${percentageChange > 0 ? 'text-green-400' : 'text-red-400'}`}>
              {percentageChange > 0 ? (
                <ArrowUp size={16} className="mr-1" />
              ) : (
                <ArrowDown size={16} className="mr-1" />
              )}
              <span className="text-sm">
                {Math.abs(percentageChange).toFixed(1)}%
              </span>
            </div>
          )}
        </div>
        <div className={`p-3 rounded-lg bg-${color}-500/10`}>
          <Icon className={`w-6 h-6 text-${color}-400`} />
        </div>
      </div>
    </div>
  );
};

const Analytics = () => {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({
    current: {
      impressions: 0,
      clicks: 0,
      conversions: 0,
      spend: 0,
      ctr: 0,
      cpc: 0,
      conversion_rate: 0,
      cost_per_conversion: 0
    },
    googleData: {},
    metaData: {},
    performanceData: [],
    platformComparison: []
  });

  // Estados y constantes
  const [platformFilter, setPlatformFilter] = useState('all');
  const [performanceMetric, setPerformanceMetric] = useState('impressions');
  const [dateRange, setDateRange] = useState('365');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');

  const COLORS = {
    blue: '#4299E1',
    green: '#48BB78',
    purple: '#9F7AEA',
    orange: '#ED8936',
    red: '#F56565',
    indigo: '#667EEA',
    yellow: '#F6E05E',
    cyan: '#4FD1C5'
  };

  // Fetch de datos unificado
  const fetchUnifiedData = useCallback(async () => {
    setLoading(true);
    const dates = getLastYearDates();

    try {
      // Fetch Google Ads data
      let googleData = {};
      if (auth.user?.google_customer_id) {
        const googleResponse = await axios.get(
          `${API_URL}/api/google/campaigns/${auth.user.google_customer_id}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
            params: dates
          }
        );
        googleData = processGoogleData(googleResponse.data);
      }

      // Fetch Meta Ads data
      let metaData = {};
      if (auth.user?.meta_ad_account_id) {
        const metaResponse = await axios.get(
          `${API_URL}/api/meta/campaigns/${auth.user.meta_ad_account_id}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
            params: dates
          }
        );
        metaData = processMetaData(metaResponse.data);
      }

      // Combine and process all data
      const combinedData = combineMetrics(googleData, metaData);
      setMetrics(prev => ({
        ...prev,
        googleData,
        metaData,
        performanceData: combinedData.dailyMetrics,
        platformComparison: [
          { name: 'Google Ads', value: combinedData.totals.google.spend },
          { name: 'Meta Ads', value: combinedData.totals.meta.spend }
        ],
        current: combinedData.totals.combined
      }));

    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [auth.token, auth.user]);
// Función para procesar datos de Google Ads
const processGoogleData = (data) => {
  const processedData = {};
  data.forEach(campaign => {
      campaign.daily_metrics.forEach(metric => {
          const date = metric.date;
          if (!processedData[date]) {
              processedData[date] = {
                  date,
                  impressions: 0,
                  clicks: 0,
                  cost: 0,
                  conversions: 0,
                  campaign_id: campaign.campaign_id,          // Añadir detalles de campaña
                  campaign_name: campaign.name,               // Añadir nombre de campaña
                  status: campaign.status,                    // Añadir status
                  channel_type: campaign.channel_type,        // Añadir tipo de canal
                  platform: 'Google Ads'                      // Identificar plataforma
              };
          }
          processedData[date].impressions += metric.impressions;
          processedData[date].clicks += metric.clicks;
          processedData[date].cost += metric.cost;
          processedData[date].conversions += metric.conversions;
      });
  });
  return processedData;
};
// Función para procesar datos de Meta Ads
const processMetaData = (data) => {
  const processedData = {};
  data.forEach(campaign => {
      if (campaign.daily_metrics) {
          campaign.daily_metrics.forEach(metric => {
              const date = metric.date;
              if (!processedData[date]) {
                  processedData[date] = {
                      date,
                      impressions: 0,
                      clicks: 0,
                      cost: 0,
                      conversions: 0,
                      campaign_id: campaign.campaign_id,      // Añadir detalles de campaña
                      campaign_name: campaign.name,           // Añadir nombre de campaña
                      status: campaign.status,                // Añadir status
                      channel_type: 'FACEBOOK',               // Tipo de canal para Meta
                      platform: 'Meta Ads'                    // Identificar plataforma
                  };
              }
              processedData[date].impressions += metric.impressions;
              processedData[date].clicks += metric.clicks;
              processedData[date].cost += metric.cost;
              processedData[date].conversions += metric.conversions;
          });
      }
  });
  return processedData;
};
  // Función para combinar métricas
  const combineMetrics = (googleData, metaData) => {
    const dailyMetrics = [];
    const allDates = new Set([
      ...Object.keys(googleData),
      ...Object.keys(metaData)
    ]);

    const totals = {
      google: {
        impressions: 0,
        clicks: 0,
        cost: 0,
        conversions: 0
      },
      meta: {
        impressions: 0,
        clicks: 0,
        cost: 0,
        conversions: 0
      },
      combined: {
        impressions: 0,
        clicks: 0,
        cost: 0,
        conversions: 0
      }
    };

    allDates.forEach(date => {
      const google = googleData[date] || {};
      const meta = metaData[date] || {};

      const dayMetrics = {
        date,
        impressions: (google.impressions || 0) + (meta.impressions || 0),
        clicks: (google.clicks || 0) + (meta.clicks || 0),
        cost: (google.cost || 0) + (meta.cost || 0),
        conversions: (google.conversions || 0) + (meta.conversions || 0),
        google_metrics: google,
        meta_metrics: meta
      };

      // Update totals
      totals.google.impressions += google.impressions || 0;
      totals.google.clicks += google.clicks || 0;
      totals.google.cost += google.cost || 0;
      totals.google.conversions += google.conversions || 0;

      totals.meta.impressions += meta.impressions || 0;
      totals.meta.clicks += meta.clicks || 0;
      totals.meta.cost += meta.cost || 0;
      totals.meta.conversions += meta.conversions || 0;

      dailyMetrics.push(dayMetrics);
    });

    // Calculate combined totals
    totals.combined = {
      impressions: totals.google.impressions + totals.meta.impressions,
      clicks: totals.google.clicks + totals.meta.clicks,
      cost: totals.google.cost + totals.meta.cost,
      conversions: totals.google.conversions + totals.meta.conversions,
      ctr: ((totals.google.clicks + totals.meta.clicks) / 
            (totals.google.impressions + totals.meta.impressions)) * 100,
      cpc: (totals.google.cost + totals.meta.cost) / 
           (totals.google.clicks + totals.meta.clicks),
      conversion_rate: ((totals.google.conversions + totals.meta.conversions) /
                       (totals.google.clicks + totals.meta.clicks)) * 100,
      cost_per_conversion: (totals.google.cost + totals.meta.cost) /
                          (totals.google.conversions + totals.meta.conversions)
    };

    return {
      dailyMetrics: dailyMetrics.sort((a, b) => a.date.localeCompare(b.date)),
      totals
    };
  };

  useEffect(() => {
    fetchUnifiedData();
  }, [fetchUnifiedData]);

  if (!auth.user?.google_customer_id && !auth.user?.meta_ad_account_id) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
        <div className="bg-gray-800 rounded-lg p-8 max-w-md w-full text-center">
          <div className="bg-blue-500/10 rounded-full p-4 w-16 h-16 mx-auto mb-6 flex items-center justify-center">
            <BarChart2 className="w-8 h-8 text-blue-400" />
          </div>
          <h2 className="text-2xl font-bold text-white mb-4">No hay cuentas conectadas</h2>
          <p className="text-gray-400 mb-6">
            Conecta tus cuentas de Google Ads o Meta Ads para comenzar.
          </p>
          <a href="/accounts" className="inline-flex items-center justify-center bg-blue-600 text-white rounded-lg px-6 py-3 hover:bg-blue-700 transition-colors gap-2 font-medium">
            <Share2 className="w-5 h-5" />
            Conectar cuentas
          </a>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center gap-4">
        <div className="animate-spin rounded-full h-16 w-16 border-4 border-blue-500 border-t-transparent"></div>
        <p className="text-gray-400">Cargando datos de campañas...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Header */}
      <div className="bg-gray-800/50 border-b border-gray-700 backdrop-blur-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div>
              <h1 className="text-2xl font-bold text-white flex items-center gap-2">
                <BarChart2 className="h-6 w-6 text-blue-400" />
                Analytics
              </h1>
              <p className="text-gray-400 text-sm">
                Análisis unificado de tus campañas publicitarias
              </p>
            </div>
            
            <div className="flex flex-wrap items-center gap-3">
              <div className="relative">
                <select
                  value={platformFilter}
                  onChange={(e) => setPlatformFilter(e.target.value)}
                  className="bg-gray-700 text-white rounded-lg border border-gray-600 px-4 py-2 pr-10 appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
                >
                  <option value="all">Todas las plataformas</option>
                  {auth.user?.google_customer_id && <option value="google">Google Ads</option>}
                  {auth.user?.meta_ad_account_id && <option value="meta">Meta Ads</option>}
                </select>
                <Radio className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
              </div>

              <button
                onClick={fetchUnifiedData}
                className="p-2 rounded-
                rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                title="Actualizar datos"
              >
                <RefreshCw className="h-5 w-5 text-gray-400" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Error Alert */}
        {error && (
          <div className="mb-6 bg-red-900/20 border border-red-700 rounded-lg p-4">
            <div className="flex items-center gap-3">
              <AlertTriangle className="h-5 w-5 text-red-400" />
              <p className="text-red-200">{error}</p>
            </div>
          </div>
        )}

        {/* Métricas principales */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          <MetricCard
            title="Impresiones"
            value={metrics.current.impressions}
            icon={Eye}
            color="blue"
          />
          <MetricCard
            title="Clicks"
            value={metrics.current.clicks}
            icon={MousePointer}
            color="green"
          />
          <MetricCard
            title="CTR"
            value={metrics.current.ctr}
            icon={Activity}
            color="purple"
            suffix="%"
          />
          <MetricCard
            title="Gasto Total"
            value={metrics.current.cost}
            icon={DollarSign}
            color="orange"
            prefix="$"
          />
          <MetricCard
            title="Conversiones"
            value={metrics.current.conversions}
            icon={ShoppingBag}
            color="indigo"
          />
          <MetricCard
            title="Costo/Conversión"
            value={metrics.current.cost_per_conversion}
            icon={Target}
            color="red"
            prefix="$"
          />
          <MetricCard
            title="Tasa de Conversión"
            value={metrics.current.conversion_rate}
            icon={Zap}
            color="yellow"
            suffix="%"
          />
          <MetricCard
            title="CPC"
            value={metrics.current.cpc}
            icon={TrendingUp}
            color="cyan"
            prefix="$"
          />
        </div>

        {/* Gráficos */}
        <div className="space-y-6">
          {/* Rendimiento en el Tiempo */}
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="flex flex-wrap items-center justify-between gap-4 mb-6">
              <div>
                <h3 className="text-lg font-semibold text-white">Rendimiento en el Tiempo</h3>
                <p className="text-sm text-gray-400">Evolución de métricas principales</p>
              </div>
              <div className="flex flex-wrap gap-2">
                {[
                  { id: 'impressions', label: 'Impresiones', color: COLORS.blue },
                  { id: 'clicks', label: 'Clicks', color: COLORS.green },
                  { id: 'conversions', label: 'Conversiones', color: COLORS.purple },
                  { id: 'cost', label: 'Gasto', color: COLORS.orange }
                ].map(metric => (
                  <button
                    key={metric.id}
                    onClick={() => setPerformanceMetric(metric.id)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-2 
                      ${performanceMetric === metric.id 
                        ? `bg-${metric.color}-600/20 text-${metric.color}-400 border border-${metric.color}-500/30`
                        : 'text-gray-400 hover:text-white hover:bg-gray-700'}`}
                  >
                    {metric.label}
                  </button>
                ))}
              </div>
            </div>
            
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={metrics.performanceData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis 
                    dataKey="date" 
                    stroke="#9CA3AF"
                    tickFormatter={(value) => format(parseISO(value), 'dd MMM', { locale: es })}
                  />
                  <YAxis 
                    stroke="#9CA3AF"
                    tickFormatter={(value) => {
                      if (performanceMetric === 'cost') {
                        return `$${value.toFixed(0)}`;
                      }
                      return value >= 1000 ? `${(value/1000).toFixed(1)}K` : value;
                    }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid #374151',
                      borderRadius: '0.5rem',
                      padding: '12px'
                    }}
                    formatter={(value, name) => {
                      if (name === performanceMetric) {
                        if (performanceMetric === 'cost') {
                          return [`$${value.toFixed(2)}`, 'Valor'];
                        }
                        return [value.toLocaleString(), 'Valor'];
                      }
                      return [value, name];
                    }}
                    labelFormatter={(value) => format(parseISO(value), 'dd MMM yyyy', { locale: es })}
                  />
                  <Area
                    type="monotone"
                    dataKey={performanceMetric}
                    fill={`${COLORS[performanceMetric === 'cost' ? 'orange' : 'blue']}20`}
                    stroke={COLORS[performanceMetric === 'cost' ? 'orange' : 'blue']}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Distribución por Plataforma */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-white mb-6">Distribución de Gasto por Plataforma</h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <RePieChart>
                    <Pie
                      data={metrics.platformComparison}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {metrics.platformComparison.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`}
                          fill={index === 0 ? COLORS.blue : COLORS.green}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      contentStyle={{
                        backgroundColor: '#1F2937',
                        border: '1px solid #374151',
                        borderRadius: '0.5rem',
                      }}
                      formatter={(value) => [`$${value.toFixed(2)}`, 'Gasto']}
                    />
                    <Legend 
                      verticalAlign="bottom" 
                      height={36}
                      formatter={(value) => <span className="text-gray-300">{value}</span>}
                    />
                  </RePieChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Tendencias por Plataforma */}
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-white mb-6">Tendencias por Plataforma</h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={metrics.performanceData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis 
                      dataKey="date" 
                      stroke="#9CA3AF"
                      tickFormatter={(value) => format(parseISO(value), 'dd MMM', { locale: es })}
                    />
                    <YAxis stroke="#9CA3AF" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: '#1F2937',
                        border: '1px solid #374151',
                        borderRadius: '0.5rem',
                      }}
                      formatter={(value) => [`$${value.toFixed(2)}`, 'Gasto']}
                      labelFormatter={(value) => format(parseISO(value), 'dd MMM yyyy', { locale: es })}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="google_metrics.cost" 
                      name="Google Ads"
                      stroke={COLORS.blue}
                      strokeWidth={2}
                      dot={false}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="meta_metrics.cost" 
                      name="Meta Ads"
                      stroke={COLORS.green}
                      strokeWidth={2}
                      dot={false}
                    />
                    <Legend />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Tabla de Datos Detallados */}
        <div className="bg-gray-800 rounded-lg p-6 mt-6">
            <div className="flex flex-wrap justify-between items-center mb-6">
                <h3 className="text-lg font-semibold text-white">Datos Detallados</h3>
                <div className="flex gap-2">
                    <input
                        type="text"
                        placeholder="Buscar campaña..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                          text-sm text-white placeholder-gray-400 focus:outline-none
                          focus:ring-2 focus:ring-blue-500 w-60"
                    />
                    <select
                        className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg
                          text-sm text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={sortField}
                        onChange={(e) => setSortField(e.target.value)}
                    >
                        <option value="date">Fecha</option>
                        <option value="campaign_id">ID</option>
                        <option value="impressions">Impresiones</option>
                        <option value="clicks">Clicks</option>
                        <option value="cost">Costo</option>
                        <option value="conversions">Conversiones</option>
                    </select>
                    <button
                        onClick={() => exportToExcel(metrics.performanceData)}
                        className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 
                          transition-colors focus:outline-none focus:ring-2 focus:ring-green-500
                          flex items-center gap-2"
                    >
                        <DownloadIcon className="w-4 h-4" />
                        Exportar Excel
                    </button>
                </div>
            </div>

            <div className="overflow-x-auto mt-4">
                <table className="w-full whitespace-nowrap">
                    <thead>
                        <tr className="text-left bg-gray-700/50">
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase">Fecha</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase">Campaign ID</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase min-w-[200px]">Nombre</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase">Status</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase">Channel</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase min-w-[150px]">Estrategia</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">Impresiones</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">Clicks</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">Costo</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">Conv.</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">CPC</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right">CTR</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right min-w-[100px]">Conv. Value</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right min-w-[100px]">Cost/Conv</th>
                            <th className="px-6 py-4 text-xs font-medium text-gray-400 uppercase text-right min-w-[100px]">Value/Conv</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-700">
                        {metrics.performanceData.map((item, index) => {
                            const campaign = item.google_metrics || item.meta_metrics;
                            if (!campaign) return null;

                            return (
                                <tr key={index} className="hover:bg-gray-700/50 transition-colors">
                                    <td className="px-6 py-4 text-sm text-gray-300">
                                        {format(parseISO(item.date), 'yyyy-MM-dd')}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300">
                                        {campaign.campaign_id || '-'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300">
                                        {campaign.campaign_name || '-'}
                                    </td>
                                    <td className="px-6 py-4 text-sm">
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                            ${campaign.status === 'ENABLED' ? 'bg-green-900/50 text-green-200' : 
                                              campaign.status === 'PAUSED' ? 'bg-yellow-900/50 text-yellow-200' :
                                              'bg-red-900/50 text-red-200'}`}>
                                            {campaign.status || '-'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300">
                                        {campaign.channel_type || '-'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300">
                                        {campaign.bidding_strategy || '-'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        {campaign.impressions?.toLocaleString() || '0'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        {campaign.clicks?.toLocaleString() || '0'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        ${campaign.cost?.toFixed(2) || '0.00'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        {campaign.conversions?.toLocaleString() || '0'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        ${(campaign.cost / campaign.clicks || 0).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        {((campaign.clicks / campaign.impressions * 100) || 0).toFixed(2)}%
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        ${campaign.conversion_value?.toFixed(2) || '0.00'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        ${(campaign.cost / campaign.conversions || 0).toFixed(2)}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-300 text-right">
                                        ${(campaign.conversion_value / campaign.conversions || 0).toFixed(2)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;