import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../components/context/AuthContext';
import { useAuth } from '../components/context/AuthContext';
import Sidebar from '../components/sidebar';
import axios from 'axios';
import _ from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

const ProtectedLayout = () => {
  const { auth, logout } = useAuth();
  const { updateUser } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Base states
  const [isVerifying, setIsVerifying] = useState(true);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [requestCount, setRequestCount] = useState(0);

  // Campaign states
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  // Catalog states
  const [catalogs, setCatalogs] = useState({
    items: [],
    pages: [],
    loading: true,
    error: null,
    lastUpdated: null
  });

  // Metrics states
  const [lastMetricsSync, setLastMetricsSync] = useState(null);
  const [metricsSyncStatus, setMetricsSyncStatus] = useState({
    google: false,
    meta: false,
    inProgress: false,
    lastError: null
  });

  // Throttled API calls
  const throttledValidateToken = useCallback(
    _.throttle(async (token) => {
      if (requestCount >= 3) return;
      
      try {
        setRequestCount(prev => prev + 1);
        const response = await axios.get(`${API_URL}/api/validate-token`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (!response.data.valid) {
          throw new Error('Token invalid');
        }
      } catch (error) {
        console.error('Token validation failed:', error);
        logout();
        navigate('/login', { 
          state: { error: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.' }
        });
      } finally {
        setRequestCount(prev => prev - 1);
        setIsVerifying(false);
      }
    }, 5000),
    [logout, navigate]
  );

  const throttledSyncMetrics = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3 || metricsSyncStatus.inProgress) return;
      
      setMetricsSyncStatus(prev => ({ ...prev, inProgress: true, lastError: null }));
      
      try {
        setRequestCount(prev => prev + 1);
        if (auth.user?.google_customer_id) {
          const googleResponse = await axios.get(
            `${API_URL}/api/metrics/sync/google/${auth.user.google_customer_id}`,
            {
              headers: { Authorization: `Bearer ${auth.token}` }
            }
          );
          
          setMetricsSyncStatus(prev => ({
            ...prev,
            google: googleResponse.data.success
          }));
        }

        if (auth.user?.meta_ad_account_id) {
          const metaResponse = await axios.get(
            `${API_URL}/api/metrics/sync/meta/${auth.user.meta_ad_account_id}`,
            {
              headers: { Authorization: `Bearer ${auth.token}` }
            }
          );
          
          setMetricsSyncStatus(prev => ({
            ...prev,
            meta: metaResponse.data.success
          }));
        }

        setLastMetricsSync(new Date());
      } catch (error) {
        console.error('Error synchronizing metrics:', error);
        setMetricsSyncStatus(prev => ({
          ...prev,
          lastError: 'Error al sincronizar métricas'
        }));
      } finally {
        setRequestCount(prev => prev - 1);
        setMetricsSyncStatus(prev => ({ ...prev, inProgress: false }));
      }
    }, 10000),
    [auth.token, auth.user]
  );

  const throttledFetchCampaigns = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3) return;
      
      setLoading(true);
      try {
        setRequestCount(prev => prev + 1);
        const [googleResponse, metaResponse] = await Promise.all([
          auth.user?.google_customer_id ? 
            axios.get(`${API_URL}/google_ads/campaigns/${auth.user.google_customer_id}`, {
              headers: { Authorization: `Bearer ${auth.token}` }
            }) : Promise.resolve({ data: [] }),
          
          auth.user?.meta_ad_account_id ?
            axios.get(`${API_URL}/api/meta/campaigns/${auth.user.meta_ad_account_id}`, {
              headers: { Authorization: `Bearer ${auth.token}` }
            }) : Promise.resolve({ data: [] })
        ]);

        const allCampaigns = [
          ...(googleResponse.data || []).map(campaign => ({
            ...campaign,
            platform: 'google_ads'
          })),
          ...(metaResponse.data || []).map(campaign => ({
            ...campaign,
            platform: 'meta_ads'
          }))
        ];

        setCampaigns(allCampaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
        setError('Error al cargar las campañas');
      } finally {
        setRequestCount(prev => prev - 1);
        setLoading(false);
      }
    }, 5000),
    [auth.token, auth.user]
  );

  // Nueva función para obtener catálogos
  const throttledFetchCatalogs = useCallback(
    _.throttle(async () => {
      if (requestCount >= 3) return;
      
      setCatalogs(prev => ({ ...prev, loading: true, error: null }));
      try {
        setRequestCount(prev => prev + 1);
        
        if (!auth.user?.meta_ad_account_id) {
          setCatalogs(prev => ({
            ...prev,
            items: [],
            pages: [],
            loading: false,
            lastUpdated: new Date()
          }));
          return;
        }

        const response = await axios.get(
          `${API_URL}/api/meta/catalogs`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );

        setCatalogs({
          items: response.data.catalogs || [],
          pages: response.data.pages || [],
          loading: false,
          error: null,
          lastUpdated: new Date()
        });

      } catch (error) {
        console.error('Error fetching catalogs:', error);
        setCatalogs(prev => ({
          ...prev,
          loading: false,
          error: 'Error al cargar los catálogos'
        }));
      } finally {
        setRequestCount(prev => prev - 1);
      }
    }, 5000),
    [auth.token, auth.user?.meta_ad_account_id]
  );

  // Token validation on mount
  useEffect(() => {
    if (!auth.token) {
      setIsVerifying(false);
      navigate('/login');
      return;
    }
    
    throttledValidateToken(auth.token);
  }, [auth.token, throttledValidateToken, navigate]);

  // Initial data load
  useEffect(() => {
    if (!isVerifying) {
      throttledFetchCampaigns();
      throttledFetchCatalogs();
    }
  }, [isVerifying, throttledFetchCampaigns, throttledFetchCatalogs]);

  // Periodic metrics sync
  useEffect(() => {
    const syncInterval = 24 * 60 * 60 * 1000; // 24 hours
    
    if (!lastMetricsSync || (new Date() - new Date(lastMetricsSync)) >= syncInterval) {
      throttledSyncMetrics();
    }

    const intervalId = setInterval(throttledSyncMetrics, syncInterval);
    return () => clearInterval(intervalId);
  }, [lastMetricsSync, throttledSyncMetrics]);

  if (isVerifying) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-400">Verificando autenticación...</p>
        </div>
      </div>
    );
  }

  if (!auth.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex h-screen bg-gray-900">
      <Sidebar 
        campaigns={campaigns}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
        setIsNewCampaignModalOpen={setIsNewCampaignModalOpen}
        onExpandedChange={setSidebarExpanded}
      />
      
      <main className={`flex-1 ${sidebarExpanded ? 'ml-64' : 'ml-16'} transition-all duration-300`}>
        <Outlet context={{ 
          campaigns,
          setCampaigns,
          catalogs,
          fetchCampaigns: throttledFetchCampaigns,
          fetchCatalogs: throttledFetchCatalogs,
          isNewCampaignModalOpen,
          setIsNewCampaignModalOpen,
          lastMetricsSync,
          syncMetrics: throttledSyncMetrics,
          metricsSyncStatus,
          loading,
          error
        }}/>
      </main>

      {(metricsSyncStatus.lastError || catalogs.error) && (
        <div className="fixed bottom-4 right-4 bg-red-900 text-white px-4 py-2 rounded-lg shadow-lg">
          {metricsSyncStatus.lastError || catalogs.error}
        </div>
      )}
    </div>
  );
};

export default ProtectedLayout;