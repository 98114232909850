// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa desde 'react-dom/client'
import App from './App';
import './index.css';

import { AuthProvider } from './components/context/AuthContext';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Crea la raíz

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App /> {/* Asegúrate de que Dashboard es un hijo de AuthProvider */}
    </AuthProvider>
  </React.StrictMode>
);
