// AuthContext.js
import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const LoadingScreen = () => (
  <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center fixed inset-0 z-50">
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="relative w-16 h-16">
        <div className="absolute inset-0 border-4 border-blue-500/20 rounded-full"></div>
        <div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
      </div>
      <p className="text-gray-400">Espere un momento...</p>
    </div>
  </div>
);

export const AuthContext = createContext();

// Control de estado de validación global
const validationState = {
  inProgress: false,
  lastValidation: null,
  validationPromise: null,
  VALIDATION_INTERVAL: 5 * 60 * 1000 // 5 minutos
};

// Gestor de caché global
const cacheManager = {
  cache: new Map(),
  timeouts: new Map(),

  set: (key, value, ttl = validationState.VALIDATION_INTERVAL) => {
    if (cacheManager.timeouts.has(key)) {
      clearTimeout(cacheManager.timeouts.get(key));
    }
    cacheManager.cache.set(key, value);
    cacheManager.timeouts.set(key, setTimeout(() => {
      cacheManager.cache.delete(key);
      cacheManager.timeouts.delete(key);
    }, ttl));
  },

  get: (key) => cacheManager.cache.get(key),

  clear: () => {
    cacheManager.timeouts.forEach(timeout => clearTimeout(timeout));
    cacheManager.cache.clear();
    cacheManager.timeouts.clear();
  }
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp && decoded.exp > currentTime) {
          return { 
            token, 
            user: { 
              ...decoded,
              campaigns: [],
              google_connected: decoded.google_connected || false,
              meta_connected: decoded.meta_connected || false,
              google_customer_id: decoded.google_customer_id || null,
              meta_ad_account_id: decoded.meta_ad_account_id || null
            } 
          };
        }
        localStorage.removeItem('access_token');
      } catch (error) {
        console.error('Error decodificando el token JWT:', error);
        localStorage.removeItem('access_token');
      }
    }
    return { token: null, user: null };
  });

  const [loading, setLoading] = useState(true);
  const validationTimeoutRef = useRef(null);
  const isLoadingRef = useRef(false);

  // Validación de token optimizada con control de concurrencia
  const validateToken = useCallback(async (token, force = false) => {
    if (!token) return false;
    
    const now = Date.now();
    const cacheKey = `token_validation_${token}`;

    // Si no es forzado y hay una validación en progreso, esperar
    if (!force && validationState.inProgress && validationState.validationPromise) {
      return validationState.validationPromise;
    }

    // Si no es forzado y hay una validación reciente en caché, usarla
    if (!force && validationState.lastValidation && 
        (now - validationState.lastValidation) < validationState.VALIDATION_INTERVAL) {
      const cachedValidation = cacheManager.get(cacheKey);
      if (cachedValidation !== undefined) {
        return cachedValidation;
      }
    }

    try {
      validationState.inProgress = true;
      validationState.validationPromise = axios.get('/api/validate-token', {
        headers: { Authorization: `Bearer ${token}` }
      });

      const response = await validationState.validationPromise;
      const isValid = response.data.valid;
      
      validationState.lastValidation = now;
      cacheManager.set(cacheKey, isValid);
      
      return isValid;
    } catch (error) {
      console.error('Error validando token:', error);
      return false;
    } finally {
      validationState.inProgress = false;
      validationState.validationPromise = null;
    }
  }, []);

  const logout = useCallback(async () => {
    if (isLoadingRef.current) return;
    
    try {
      isLoadingRef.current = true;
      if (auth.token) {
        await axios.post('/api/logout', {}).catch(() => {});
      }
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      localStorage.clear();
      cacheManager.clear();
      delete axios.defaults.headers.common['Authorization'];
      setAuth({ token: null, user: null });
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
      isLoadingRef.current = false;
    }
  }, [auth.token]);

  const login = useCallback(async (token, userData = null) => {
    if (isLoadingRef.current) return false;
    
    try {
      isLoadingRef.current = true;
      const isValid = await validateToken(token, true);
      if (!isValid) throw new Error('Token inválido');

      localStorage.setItem('access_token', token);
      const decoded = jwtDecode(token);
      
      setAuth({ 
        token, 
        user: { 
          ...decoded,
          ...userData,
          campaigns: [],
          google_connected: decoded.google_connected || false,
          meta_connected: decoded.meta_connected || false,
          google_customer_id: decoded.google_customer_id || null,
          meta_ad_account_id: decoded.meta_ad_account_id || null
        } 
      });

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return true;
    } catch (error) {
      console.error('Error en login:', error);
      await logout();
      return false;
    } finally {
      isLoadingRef.current = false;
    }
  }, [logout, validateToken]);

  const updateUser = useCallback(async (userData) => {
    if (isLoadingRef.current || !auth.token) return false;

    try {
      isLoadingRef.current = true;
      const response = await axios.put('/api/user', userData);
      
      if (response.data) {
        setAuth(prev => ({
          ...prev,
          user: { ...prev.user, ...response.data }
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error updating user:', error);
      if (error.response?.status === 401) {
        await logout();
      }
      return false;
    } finally {
      isLoadingRef.current = false;
    }
  }, [auth.token, logout]);

  // Configurar interceptores de Axios una sola vez
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        if (auth.token) {
          config.headers['Authorization'] = `Bearer ${auth.token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response?.status === 401 && auth.token) {
          await logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      cacheManager.clear();
    };
  }, [auth.token, logout]);

  // Validación inicial optimizada
  useEffect(() => {
    const validateSession = async () => {
      if (!auth.token || isLoadingRef.current) {
        setLoading(false);
        return;
      }

      try {
        isLoadingRef.current = true;
        const isValid = await validateToken(auth.token);
        if (!isValid) {
          await logout();
        }
      } catch (error) {
        await logout();
      } finally {
        setLoading(false);
        isLoadingRef.current = false;
      }
    };

    validateSession();

    return () => {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
      isLoadingRef.current = false;
    };
  }, [auth.token, logout, validateToken]);

  if (loading) return <LoadingScreen />;

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        updateUser,
        validateToken,
        isAuthenticated: !!auth.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

// Configuración global de Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';